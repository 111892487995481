<template>
    <el-dialog width="600" v-bind:title="$t('CS.point')" :visible="dialogPointVisible" @close="dialogPointOnClose()">
        <el-form ref="dataForm" :rules="rules" :model="updateQuery" size="small">
            <el-row :gutter="10">
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11" v-if="serviceList.length>0">
                    <el-form-item prop="serviceId" v-bind:label="$t('Operator')">
                        <el-select id="serviceId" v-model="serviceId" :disabled="this.isModify" size="small" class="filter-item">
                            <el-option
                                  v-for="item in this.filterServiceList"
                                  :label="item.name"
                                  :key="item.id"
                                  :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="stationId" v-bind:label="$t('ChargingStation')">
                        <el-select id="stationId" v-model="updateQuery.stationId" :disabled="this.isModify" size="small" class="filter-item">
                            <el-option
                                  v-for="item in this.chargingStations"
                                  :label="item.name"
                                  :key="item.id"
                                  :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="name" v-bind:label="$t('Name')">
                        <el-input id="name" size="small" v-model="updateQuery.name" :placeholder="$t('Input')+' '+$t('Name')" class="filter-item" clearable/>
                    </el-form-item>
                </el-col>
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="enabled" v-bind:label="$t('EnableStatus')">
                        <el-select id="enabled" v-model="updateQuery.enabled" size="small" class="filter-item">
                            <el-option v-bind:label="$t('Enable')" :value="true" />
                            <el-option v-bind:label="$t('Disable')" :value="false" />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="lon" v-bind:label="$t('Longitude')">
                        <el-input id="lon" size="small" v-model="updateQuery.lon" :placeholder="$t('Input')+' '+$t('Longitude')" class="filter-item" clearable/>
                    </el-form-item>
                </el-col>
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="lat" v-bind:label="$t('Latitude')">
                        <el-input id="lat" size="small" v-model="updateQuery.lat" :placeholder="$t('Input')+' '+$t('Latitude')" class="filter-item" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="numberOfConnectors" v-bind:label="$t('ChargingConnectorNum')">
                        <el-select id="numberOfConnectors" v-model="updateQuery.numberOfConnectors" size="small" class="filter-item">
                            <el-option label="1" :value="1" />
                            <el-option label="2" :value="2" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="ratedPowerOutput" v-bind:label="$t('RatedOutputPower')">
                        <el-input id="ratedPowerOutput" size="small" v-model="updateQuery.ratedPowerOutput" :placeholder="$t('Input')+' '+$t('RatedOutputPower')+' (kw)'" class="filter-item" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="ocppScMaxValue" v-bind:label="$t('Maximum')+$t('Current')">
                        <el-input id="ocppScMaxValue" size="small" v-model="updateQuery.ocppScMaxValue" :placeholder="$t('Input')+' '+$t('Maximum')+$t('Current')+'(A)'" class="filter-item" clearable/>
                    </el-form-item>
                </el-col>
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="ocppScMinValue" v-bind:label="$t('Minimum')+$t('Current')">
                        <el-input id="ocppScMinValue" size="small" v-model="updateQuery.ocppScMinValue" :placeholder="$t('Input')+' '+$t('Minimum')+$t('Current')+'(A)'" class="filter-item" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="10">   <!--bowa add for ocpp version 12/19-->
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="ocppVersion" v-bind:label="$t('OCPPVerSelect')">
                        <el-select id="ocppVersion" v-model="updateQuery.ocppVersion" size="small" class="filter-item">
                            <el-option v-for="item in ocppVersionList" :key="item.id" :label="item.name " :value="item.value" />
                        </el-select>   
                    </el-form-item>
                </el-col>
            </el-row>

        </el-form>
        <el-button style="margin-bottom: 0px; margin-top: 10px;" size="median" type="primary" v-if="!this.enabledOCPI" @click="updatePoint()">{{$t('Save')}}</el-button>
    </el-dialog>
</template>

<script>
import { mixins } from '@/views/common/mixins.js'
import {apiUrl} from "@/utils/main";

export default {
    name: 'PointSetting',
    mixins: [mixins],
    props: { //一個元件需要明確地宣告它所接受的props，這樣Vue 才能知道外部傳入的哪些是props
        pointData: {
            require: false,
            default: undefined,
            type: Object
        },
        serviceList: {
            require: false,
            default: undefined,
            type: Array
        },
        dialogPointVisible: {
            require: true,
            default: undefined,
            type: Boolean
        }
    },
    data() {
        let latValid = (rule, value, callback) =>{
            if(/^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/.test(value)){
                callback();
            }else{
                callback(new Error("緯度格式不正確"));
            }
        };
        let lonValid = (rule, value, callback) =>{
            if(/^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/.test(value)){
                callback();
            }else{
                callback(new Error("經度格式不正確"));
            }
        };
        let rateValid = (rule, value, callback) =>{
            if(/^[0-9]+\.?[0-9]*$/.test(value)){
                callback();
            }else{
                callback(new Error("請輸入數值或小數點"));
            }
        };
        let numberValid = (rule, value, callback) =>{
            if (!value) callback();
            if(/^[0-9]+\.?[0-9]*$/.test(value)){
                callback();
            }else{
                callback(new Error("請輸入數值或小數點"));
            }
        };
        return {
            isModify: false,
            chargingStations: this.$store.state.chargingStations,
            serviceId: undefined,
            updateQuery: {
                id: undefined,
                stationId: undefined,
                name: undefined,
                lat: undefined,
                lon: undefined,
                numberOfConnectors: "1",
                ratedPowerOutput: undefined,
                enabled: true,
                ocppScMaxValue: undefined,
                ocppScMinValue: undefined,
                ocppVersion:undefined,
                      
            },
            enabledOCPI: false,
            filterServiceList: [],
            rules: {
                stationId: [{ required: true, message: '(必填)', trigger: 'blur' }],
                name: [{ required: true, message: '(必填)', trigger: 'blur' }],
                lat: [{ required: true, validator: latValid, trigger: 'blur' }],
                lon: [{ required: true, validator: lonValid, trigger: 'blur' }],
                numberOfConnectors: [{ required: true, message: '(必填)', trigger: 'blur' }],
                ratedPowerOutput: [{ required: true, validator: rateValid, trigger: 'blur' }],
                ocppScMaxValue: [{ validator: numberValid, trigger: 'blur' }],
                ocppScMinValue: [{ validator: numberValid, trigger: 'blur' }],
                enabled: [{ required: true, message: '(必填)', trigger: 'blur' }]
            },
            //bowa add 1223
            ocppVersionList :[
              {id:1 , name:"ocpp1.6",value:"ocpp1.6" },
              {id:2 , name:"非OCPP版本" ,value:""},
               
            ], 
        };
    },
    watch: {
        pointData(val) {
            if (val) {
                this.isModify = true
                this.serviceId = val.station.service.id
                this.updateQuery.id = val.id
                this.updateQuery.name = val.name
                this.updateQuery.stationId = val.station.id
                this.updateQuery.lat = val.lat
                this.updateQuery.lon = val.lon
                this.updateQuery.numberOfConnectors = val.numberOfConnectors
                this.updateQuery.ratedPowerOutput = val.ratedPowerOutput
                this.updateQuery.enabled = val.enabled
                this.updateQuery.ocppScMaxValue = val.ocppScMaxValue
                this.updateQuery.ocppScMinValue = val.ocppScMinValue
                this.enabledOCPI = val.station.enabledOCPI
                this.updateQuery.ocppVersion = val.ocppVersion //bowa add 1223
           
            }
        },
        serviceList(val) {
            if (val)
                this.filterServiceList = this.serviceList.filter(item => item.enabledOCPI==false)
        },
        'serviceId': function () {
            this.chargingStations = (this.$store.state.chargingStations || []).filter(item => item.service_id == this.serviceId)
            if (!this.isModify) this.updateQuery.stationId = undefined
        },
    },
    async created() {
    },
    methods: {
        dialogPointOnClose() {
            this.resetUpdateQuery()
            this.$refs.dataForm.resetFields() // 表單校驗結果重置
            this.$emit('close') // 觸發父層 close
        },
        updatePoint() {
            //console.log('pointData:',this.updateQuery.ocppVersion)
            this.$refs['dataForm'].validate((valid) => {
                if (valid) {
                    this.axios.put('/api/v1/point', this.updateQuery).then(() => {
                        this.$store.dispatch("getPointByCurrentAccount") // 更新所有充電樁資訊
                        this.$message({
                            showClose: true,
                            message: '修改成功',
                            type: 'success'
                        })
                        this.dialogPointOnClose()
                    }).catch(err => {
                        this.$message({
                            message: '修改失敗 '+err.data,
                            type: 'error'
                        })
                    })
                } else {
                    this.$message({
                        message: '輸入欄位格式不正確',
                        type: 'error'
                    })
                }
            })
        },
        resetUpdateQuery() {
            this.isModify = false
            this.serviceId = undefined
            this.updateQuery = {
                id: undefined,
                stationId: undefined,
                name: undefined,
                lat: undefined,
                lon: undefined,
                numberOfConnectors: "1",
                ratedPowerOutput: undefined,
                enabled: true,
                ocppScMaxValue: undefined,
                ocppScMinValue: undefined,
                ocppVersion: undefined,  //bowa add 12/19
            }
            this.enabledOCPI = false
        }
    }
}
</script>
<style>
/* Medium devices (tablets, 768px and up) */
@media (max-width: 767.98px) {
    .el-form-item__content {
        text-align: left;
    }
}
@media (min-width: 768px) {
    .el-input {
        width: 200px;
    }
    .el-select {
        width: 200px;
    }
    .el-form-item__content {
        text-align: left;
        margin-left: 110px;
    }
    .el-form-item__label {
        width: 110px;
    }
 }
</style>