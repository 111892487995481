<template>
  <div>
    <el-row>
      <el-form label-width="auto" style="max-width: 100px" id="selectForm">
        <el-form-item label="營運商">
          <el-select v-model="listQuery.serviceId" size="small" v-bind:placeholder="$t('AllOperator')" class="filter-item-mid" v-if="serviceList.length>0">
            <el-option v-for="item in serviceList" :key="item.id" :label="item.name + ' (' + item.code + ')'" :value="item.id" />
          </el-select>
        </el-form-item>
      </el-form>

    </el-row>
    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" style="margin-bottom: 10px; padding: 7px;">
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix">
        <span class="role-span">CPO合作營運商</span>
        <el-button style="float: right;" type="primary" size="small" @click="showSetting('CPO')">編輯合作關係</el-button>
      </div>
      <el-table
          highlight-current-row
          v-loading="listLoading"
          :data="service.partnerCPOs" style="width: 100%;"
          :cell-style="{padding: '0', height: '40px'}"
          @current-change="handleCurrentChange"
      >
        <el-table-column align="center" prop="id" label="ID" />
        <el-table-column align="center" prop="name" :label="$t('Name')" />
        <el-table-column align="center" prop="code" :label="$t('Code')" />
        <el-table-column align="center" prop="ocpiPartyId" label="Party ID" />
        <el-table-column align="center" prop="ocpiCountryCode" label="國碼" />
        <el-table-column align="center" prop="ocpiVersionId" label="版本" />
        <el-table-column align="center" :label="$t('Operate')" width="130px">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="寄送服務介接資訊" placement="top-start">
              <el-button type="success" size="mini" icon="el-icon-message" @click="handleSendMail(scope.row,'CPO')" />
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="啟動服務介接" placement="top-start">
              <el-button type="info" size="mini" icon="el-icon-position" @click="handleCredentialsChange(scope.row)" />
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    </el-col>
    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" style="padding: 7px;">
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix">
        <span class="role-span">eMSP合作營運商</span>
        <el-button style="float: right;" type="primary" size="small" @click="showSetting('eMSP')">編輯合作關係</el-button>
      </div>
      <el-table
          highlight-current-row
          v-loading="listLoading"
          :data="service.partnerEMSPs" style="width: 100%;"
          :cell-style="{padding: '0', height: '40px'}"
          @current-change="handleCurrentChange"
      >
        <el-table-column align="center" prop="id" label="ID" />
        <el-table-column align="center" prop="name" :label="$t('Name')" />
        <el-table-column align="center" prop="code" :label="$t('Code')" />
        <el-table-column align="center" prop="ocpiPartyId" label="Party ID" />
        <el-table-column align="center" prop="ocpiCountryCode" label="國碼" />
        <el-table-column align="center" prop="ocpiVersionId" label="版本" />
        <el-table-column align="center" :label="$t('Operate')" width="130px">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="寄送服務介接資訊" placement="top-start">
              <el-button type="success" size="mini" icon="el-icon-message" @click="handleSendMail(scope.row,'EMSP')" />
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="啟動服務介接" placement="top-start">
              <el-button type="info" size="mini" icon="el-icon-position" @click="handleCredentialsChange(scope.row)" />
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    </el-col>

    <CooperationSettingCPO :dialogVisible="dialogSettingVisibleCPO" :serviceData="serviceData" @close="dialogSettingOnCloseCPO" />
    <CooperationSettingEMSP :dialogVisible="dialogSettingVisibleEMSP" :serviceData="serviceData" @close="dialogSettingOnCloseEMSP" />
  </div>
</template>

<script>
import {mixins} from "@/views/common/mixins";
import CooperationSettingCPO from "@/views/ocpi/CooperationSettingCPO";
import CooperationSettingEMSP from "@/views/ocpi/CooperationSettingEMSP";

export default {
  name: "Cooperation",
  components: {
    CooperationSettingCPO,
    CooperationSettingEMSP
  },
  mixins: [mixins],
  data() {
    return {
      dialogSettingVisibleCPO: false,
      dialogSettingVisibleEMSP: false,
      listLoading: false,
      listQuery: {
        type: 'general',
        enabled: true,
        name: undefined,
        serviceId: undefined,
        page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
        size: 15
      },
      sendMail: {
        masterServiceId: undefined,
        partnerServiceId: undefined,
        partnerRole: undefined,
      },
      credentialChange: {
        masterServiceId: undefined,
        partnerServiceId: undefined,
      },
      serviceList: [],
      service: {
        id:undefined,
        code:undefined,
        description:undefined,
        enabledOCPI:undefined,
        partnerCPOs:[],
        partnerEMSPs:[],
      },
      serviceData:undefined,
      editObjectType: undefined
    };
  },
  watch:{
    'listQuery.serviceId': function () {
      this.getOCPIService();
    },
  },
  methods: {
    getOCPIEnabledServiceOptions() {
      this.listLoading = true
      this.axios.get('/api/v1/ocpi/service').then(res => {
        this.serviceList = res.data
        this.listQuery.serviceId = this.serviceList[0].id;
        //console.log(this.serviceList);
        this.getOCPIService();
      })
    },
    getOCPIService() {
      this.listLoading = true
      this.axios.get('/api/v1/ocpi/service/'+this.listQuery.serviceId, { params: this.listQuery }).then(res => {
        console.log('getOCPIService',res)
        this.service = res.data
        // this.total = res.data.totalElements
        this.listLoading = false
      })
    },
    handleCurrentChange(){

    },
    handleSendMail(row,partnerRole){
      console.log('handleSendMail',partnerRole,row);
      this.sendMail.masterServiceId = this.listQuery.serviceId;
      this.sendMail.partnerServiceId = row.id;
      this.sendMail.partnerRole = partnerRole;

      this.listLoading = true
      this.axios.post('/api/v1/ocpi/sendMail',this.sendMail ).then(res => {
            this.$message({
              showClose: true,
              message: this.$t('Success'),
              type: 'success'
            })
            this.listLoading = false
            //this.dialogDataOnClose()
          }).catch(err => {
            console.log('err:',err)
            this.$message({
              message: this.$t('Fail') + ' ('+err.data.msg+')' ,
              type: 'error'
            })
            this.listLoading = false
          });
    },
    handleCredentialsChange(row){
      console.log('handleCredentialsChange',row);
      this.credentialChange.masterServiceId = this.listQuery.serviceId;
      this.credentialChange.partnerServiceId = row.id;

      this.listLoading = true
      this.axios.post('/adaptor/credential-exchange',this.credentialChange ).then(res => {
        //console.log(res);
        this.$message({
          showClose: true,
          message: this.$t('Success'),
          type: 'success'
        })
        this.listLoading = false
        //this.dialogDataOnClose()
      }).catch(err => {
        console.log('err:',err)
        this.$message({
          message: this.$t('Fail') + ' ('+err.data.msg+')' ,
          type: 'error'
        })
        this.listLoading = false
      });

    },
    showSetting(editType) {
      this.serviceData = this.service;
      this.editObjectType = editType;
      console.log('showSetting',this.editObjectType);
      if(editType == 'CPO')
        this.dialogSettingVisibleCPO = true;
      else
        this.dialogSettingVisibleEMSP = true;
    },
    dialogSettingOnCloseCPO() {
      this.dialogSettingVisibleCPO = false
      console.log('dialogSettingOnCloseCPO',this.dialogSettingVisibleCPO);
      this.getOCPIService();
      //this.groupData = undefined
      //this.resetPage(this.getList)
    },
    dialogSettingOnCloseEMSP() {
      this.dialogSettingVisibleEMSP = false
      console.log('dialogSettingOnCloseEMSP',this.dialogSettingVisibleEMSP);
      //this.groupData = undefined
      //this.resetPage(this.getList)
    },
  },
  created() {
    this.getOCPIEnabledServiceOptions();
  }
}
</script>

<style scoped>
.role-span {
  font-weight: bold;color: #303133;
  font-size: 15px;
}

.text {
  font-size: 14px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}

</style>
