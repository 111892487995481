import Vue from 'vue'
import VueRouter from 'vue-router'
import { publicPath } from '../../vue.config'
import Layout from '../layout/index'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Main',
  //   component: Layout,
  //   redirect: '/dashboard'
  // },
  {
    path: '/login',
    name: 'Login',
    component: (resolve) => require(['@/views/login/index'], resolve),
  },
  {
    path: '/refresh',
    name: 'refresh',
    component: (resolve) => require(['@/views/report/refresh'], resolve),
  },

  // {
  //   path: '/403',
  //   name: '無權限',
  //   component: (resolve) => require(['@/views/Page403'], resolve)
  // },
  // {
  //   path: '/404',
  //   name: '找不到此頁面',
  //   component: (resolve) => require(['@/views/Page404'], resolve)
  // },
  // {
  //   path: '*',
  //   redirect: '/404',
  //   hidden: true
  // },
  {
    path: '/',
    name: 'Main',
    component: Layout,
    redirect: '/dashboard',
    children: [

      {
        path: 'dashboard',
        //name: '儀表板',//替換語系
          name: 'DashBoard',
          component: (resolve) => require(['@/views/dashboard/index'], resolve),
      },
      {
            path: 'payment_issue',
            //name: '儀表板',//替換語系
            name: 'PaymentIssue',
            component: (resolve) => require(['@/views/dashboard/PaymentIssue'], resolve),
      },
      {
        path: 'report/station',
      //name: '充電站充電報表',//替換語系
        name: 'Rp.station',
        component: (resolve) => require(['@/views/report/Station'], resolve),
      },
      {
        path: 'report/status',
      //name: '充電樁狀態報表',//替換語系
        name: 'Rp.status',
        component: (resolve) => require(['@/views/report/Status'], resolve),
      },
      {
        path: 'report/usage',
      //name: '充電樁使用次數與使用率報表',//替換語系
        name: 'Rp.usage_record',
        component: (resolve) => require(['@/views/report/Usage'], resolve),
      },
      {
        path: 'report/availability',
      //name: '充電樁使用次數與使用率報表',//替換語系
        name: 'Rp.equipment_availability',
        component: (resolve) => require(['@/views/report/Availability'], resolve),
      },
      {
        path: 'report/popular',
      //name: '充電樁使用次數與使用率報表',//替換語系
        name: 'Rp.popular_rate',
        component: (resolve) => require(['@/views/report/Popular'], resolve),
      },
      {
        path: 'report/cp',
      //name: '碳排放報表',//替換語系
        name: 'Rp.carbon_emmision',
        component: (resolve) => require(['@/views/report/Carbon'], resolve),
      },
      {
        path: 'accounting-report/invoice',
      //name: '請款紀錄報表',//替換語系
        name: 'ARp.invoice',
        component: (resolve) => require(['@/views/accounting-report/Invoice'], resolve),
      },
      {
        path: 'accounting-report/refund',
      //name: '退款紀錄報表',//替換語系
        name: 'ARp.refund',
        component: (resolve) => require(['@/views/accounting-report/Refund'], resolve),
      },
      {
        path: 'accounting-report/difference',
      //name: '每月對帳報表',//替換語系
        name: 'ARp.difference',
        component: (resolve) => require(['@/views/accounting-report/Difference'], resolve),
      },
      {
        path: 'accounting-report/todolist',
        //name: '待辦事項',//替換語系
        name: 'ARp.todolist',
        component: (resolve) => require(['@/views/accounting-report/ARpTodolist'], resolve),
      },
      {
        path: 'operating-report/counting_record',
      //name: '充電服務帳務紀錄報表',//替換語系
        name: 'ORp.counting_record',
        component: (resolve) => require(['@/views/operating-report/Counting_record'], resolve),
      },
      {
        path: 'pay/user',
      //name: '會員管理',//替換語系
        name: 'Pay.user',
        component: (resolve) => require(['@/views/user/User'], resolve),
      },
      {
        path: 'user/push',
      //name: '會員推播通知',//替換語系
        name: 'Ap.push',
        component: (resolve) => require(['@/views/user/PushNotification'], resolve),
      },
      {
        path: 'system/profile',
      //name: '個人資料維護',//替換語系
        name: 'Sys.profile',
        component: (resolve) => require(['@/views/system/Profile'], resolve),
      },
      {
        path: 'system/account',
      //name: '系統帳號管理',//替換語系
        name: 'Sys.account',
        component: (resolve) => require(['@/views/system/Account'], resolve),
      },
      {
        path: 'system/service',
      //name: '營運商管理',//替換語系
        name: 'Sys.service',
        component: (resolve) => require(['@/views/system/Service'], resolve),
      },
      {
        path: 'system/rule',
        //name: '營運商管理',//替換語系
        name: 'Sys.rule',
        component: (resolve) => require(['@/views/system/Role'], resolve),
      },
      {
        path: 'system/logs',
        name: 'Sys.logs',
        component: (resolve) => require(['@/views/system/Logs'], resolve),
       },
      {
        path: 'pay/tappay',
        //name: 'Tappay多元支付',//替換語系
        name: 'Pay.tappay',
        component: (resolve) => require(['@/views/pay/Tappay'], resolve),
      },
      {
        path: 'trade/record',
      //name: '充電與交易紀錄',//替換語系
        name: 'Pay.tradingrecord',
        component: (resolve) => require(['@/views/trade/Record'], resolve),
     },
     {
       path: 'trade/charging',
       //name: '充電與交易紀錄',//替換語系
       name: 'Pay.chargingrecord',
       component: (resolve) => require(['@/views/trade/Charging'], resolve),
     },
     {
      path: 'trade/rfid',
      //name: '充電與交易紀錄',//替換語系
      name: 'Pay.rfidrecord',
      component: (resolve) => require(['@/views/trade/Rfid'], resolve),
    },
    {
        path: 'trade/todolist',
        //name: '充電與交易紀錄',//替換語系
        name: 'Pay.todolist',
        component: (resolve) => require(['@/views/trade/TradeTodoList'], resolve),
     },
     {
       path: 'station/station',
       name: 'CS.station',
       component: (resolve) => require(['@/views/station/Stations'], resolve),
     },
     {
       path: 'station/point',
       name: 'CS.point',
       component: (resolve) => require(['@/views/station/Point'], resolve),
     },
     {
        path: 'station/connector',
        name: 'CS.connector',
        component: (resolve) => require(['@/views/station/Connector'], resolve),
      },
      {
        path: 'station/meterIoT',
        name: 'CS.meterIoT',
        component: (resolve) => require(['@/views/station/MeterIoT'], resolve),
      },
      {
        path: 'station/meter',
        name: 'CS.meter',
        component: (resolve) => require(['@/views/station/Meter'], resolve),
      },
      {
        path: 'station/parkingspace',
        name: 'CS.parkingspace',
        component: (resolve) => require(['@/views/station/ParkingSpace'], resolve),
      },
      {
        path: 'station/parkingspacebinding',
        name: 'CS.parkingspacebinding',
        component: (resolve) => require(['@/views/station/ParkingSpaceBinding'], resolve),
      },
      {
        path: 'activity/announcement',
        name: 'Act.announcement',
        component: (resolve) => require(['@/views/activity/Announcement'], resolve),
      },
      {
        path: 'activity/discount',
        name: 'Act.discount',
        component: (resolve) => require(['@/views/activity/Discount'], resolve),
      },
      {
        path: 'activity/maintenance',
        name: 'Act.maintenance',
        component: (resolve) => require(['@/views/activity/Maintenance'], resolve),
      },
      {
        path: 'application/apikey',
        name: 'Ap.api_management',
        component: (resolve) => require(['@/views/application/ApiKey'], resolve),
      },
      {
        path: 'alert/record',
      //name: '事件紀錄',//替換語系
        name: 'Ar.record',
        component: (resolve) => require(['@/views/alert/record'], resolve),
      },
      {
        path: 'alert/event',
      //name: '事件告警',//替換語系
        name: 'Ar.event',
        component: (resolve) => require(['@/views/alert/event'], resolve),
      },
      {
        path: 'alert/setting',
      //name: '告警通知設定',//替換語系
        name: 'Ar.setting',
        component: (resolve) => require(['@/views/alert/setting'], resolve),
      },
      {
        path: 'energy/schedule',
        name: 'En.schedule',
        component: (resolve) => require(['@/views/energy/schedule'], resolve),
      },
      {
        path: 'energy/dynamic',
        name: 'En.dynamic',
        component: (resolve) => require(['@/views/energy/dynamic'], resolve),
      },
      {
        path: 'dashboard/control',
      //name: '遠端控制',//替換語系
        name: 'DB.control',
        component: (resolve) => require(['@/views/dashboard/control'], resolve),
       },
       {
        path: 'station/RfidManage',
      //name: '遠端控制',//替換語系
        name: 'CS.rfid_setting',
        component: (resolve) => require(['@/views/station/RfidManage'], resolve),
       },

       {
        path: 'price/plan',
      //name: '時間電價',//替換語系
        name: 'En.usagerecord',
        component: (resolve) => require(['@/views/price/timeofuse'], resolve),
       },
       {
        path: 'price/metercontract',
        name: 'En.metercontract',
        component: (resolve) => require(['@/views/price/metercontract'], resolve),
       },
       {
        path: 'price/package',
        name: 'En.package',
        component: (resolve) => require(['@/views/price/package'], resolve),

        },
        {
            path: 'ocpi/cooperation',
            name: 'Ocpi.cooperation',
            component: (resolve) => require(['@/views/ocpi/Cooperation'], resolve),
        },
        {
            path: 'ocpi/api-management',
            name: 'Ocpi.api_management',
            component: (resolve) => require(['@/views/ocpi/ApiManagement'], resolve),
        },
      {
        path: 'ocpi/token',
        name: 'Ocpi.token',
        component: (resolve) => require(['@/views/ocpi/token'], resolve),
      },
      {
        path: 'ocpi/tokenconfig',
        name: 'Ocpi.tokenconfig',
        component: (resolve) => require(['@/views/ocpi/tokenConfig'], resolve),
      },
      {
        path: 'ocpi/command',
        name: 'Ocpi.command',
        component: (resolve) => require(['@/views/ocpi/command'], resolve),
      },
      {
        path: 'ocpi/cdr',
        name: 'Ocpi.cdr',
        component: (resolve) => require(['@/views/ocpi/cdr'], resolve),
      },
      {
        path: 'ocpi/logs',
        name: 'Ocpi.logs',
        component: (resolve) => require(['@/views/ocpi/Logs'], resolve),
      },
      {
        path: 'ITArea/PaymentIssue',
        name: 'PaymentIssue',
        component: (resolve) => require(['@/views/ITArea/PaymentIssue'], resolve),
      },
      {
        path: 'ITArea/TappayAccounting',
        name: 'TappayAccounting',
        component: (resolve) => require(['@/views/ITArea/TappayAccounting'], resolve),
      },

      // {
      //   path: 'login',
      //   name: 'login',
      //   component: (resolve) => require(['@/views/login/index'], resolve),
      // },
      /* ChtOne test */
      /*
      {
        path: 'query',
        name: 'ChtOne RWD前端頁面1',
        component: (resolve) => require(['@/views/query/evstation'], resolve),
        },
      {
        path: 'querypoint',
        name: 'ChtOne RWD前端頁面2',
        component: (resolve) => require(['@/views/query/evpoint'], resolve),
        },
      */
    ],

  }
]

const router = new VueRouter({
  //mode: 'history',
  mode: 'hash',
  base: publicPath,
  // base: process.env.BASE_URL,
  routes
})


// 切換路由時，移除以「欲前往頁面 option.name + _xxx」 為 key 的 localStorage
router.beforeEach((to, from, next) => {
  // if (to.matched[1]) {
  //   let targetRoute = to.matched[1]
  //   if (targetRoute?.components?.default?.name) {
  //     localStorage.removeItem(targetRoute.components.default.name + '_Basic') // 基本篩選設定
  //     localStorage.removeItem(targetRoute.components.default.name + '_Alarm') // 案件特性篩選
  //   }
  // }
  // next()
  //   if (!store.state.isAuthenticated) next({ name: 'Login' })
    //else next()
    // if(sessionStorage.getItem("csrf_token"))
    // {
    //     sessionStorage.removeItem("csrf_token");
    // }
  if (to.name !== 'Login' && !sessionStorage.getItem("csrf_token")) next({ name: 'Login' })
   else next()

  // if (!sessionStorage.getItem("csrf_token")){
  //   next({ name: 'Login' })
  // }
  //next()

    // console.log("222");
    // console.log("store.state.isAuthenticated",store.state.isAuthenticated);
    // if (to.name !== 'Login' && !store.state.isAuthenticated)
    // {
    //     next({ name: 'Login' })
    // }
    //
    // else next()

    //next();

})

export default router
