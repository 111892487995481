<template>
    <div >
      <el-row>
            <div style="float: right;">
                <el-date-picker
                    v-model="dateRange"
                    size="small"
                    class="filter-item"
                    type="datetimerange"
                    format="yyyy-MM-dd HH:mm:ss"
                    range-separator="-"
                    date-format="YYYY-MM-DD"
                    time-format="HH:mm:ss"
                    :start-placeholder="$t('Start')+$t('Date')"
                    :end-placeholder="$t('End')+$t('Date')">  <!--替換:開始日期、結束日期-->
                </el-date-picker>
                <el-select v-model="listQuery.role" size="small" :placeholder="$t('AllCategories')" class="filter-item-mid">
                  <el-option v-for="item in roleOptions" :key="item.id" :label="item.label " :value="item.name" />
                </el-select>
                <el-select v-model="listQuery.module" size="small" :placeholder="$t('AllCategories')" class="filter-item-mid">
                  <el-option v-for="item in moduleOptions" :key="item.id" :label="item.label " :value="item.name" />
                </el-select>
            </div>
      </el-row>
      <el-row style="text-align: left; font-size: .8em; padding-right: 10px; color: dimgrey; padding-left: 10px; margin-bottom: 5px;" >
        <span >產製時間: {{ reportDate }} </span>
      </el-row>
      <!-- table -->
      <el-table  v-loading="listLoading" :data="list" style="width: 100%;" :cell-style="{padding: '0', height: '40px', textAlign:'left'}" :default-sort="defaultSort" @sort-change="logTableSortChange">
        <el-table-column align="center" prop="timestamp" label="時間"  :show-overflow-tooltip="true" width="180px" sortable="custom" >
          <template slot-scope="scope">
            <span :title="scope.row.timestamp">{{ parseDateTimeToHHMMSS(scope.row.timestamp) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="role" label="角色"  sortable="custom"  />
        <el-table-column align="center" prop="method" label="方法" sortable="custom"  />
        <el-table-column align="center" prop="protocol" label="協議模式" sortable="custom"  />
        <el-table-column align="center" prop="module" label="協議類型" sortable="custom"  />
          
        <el-table-column align="center" prop="url" label="路徑" sortable="custom"  />
        <el-table-column align="center" prop="responseStatus" label="回覆結果"  sortable="custom"  />
        <el-table-column align="center" prop="func" label="功能" >
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="showDetail(scope.row)" ><svg-icon iconName="view-details" /></el-button>
            
          </template>
        </el-table-column>
      </el-table>
      <!--分頁組件-->
      <pagination v-show="total>0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size" @pagination="getList" />
  
    </div>
  </template>
  
  <script>
  import Pagination from '@/components/Pagination'
  import { mixins } from '@/views/common/mixins.js'
  import {refreshToken,stopRefreshToken} from "@/utils/auth";
  import {apiUrl} from "@/utils/main";
  import { ColorPicker } from 'element-ui';
  
  export default {
    name: 'Service',
    components: {
      Pagination
    },
    mixins: [mixins],
    data() {
      return {
        dialogWidth: 0,
        total: 0,
        logs: [],  // 用來儲存原始 logs 數據
        list: [],
        listLoading: false,
        listQuery: {
          enabled: true,
          serviceId: undefined,
          startdate: undefined,
          enddate: undefined,
          name: undefined,
          role: undefined,
          module: undefined,
          item: undefined,
          page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
          size: 15
        },
        defaultSort: { prop: 'timestamp', order: 'descending' },
        dateRange: undefined,
        serviceList: [],
        moduleOptions: [
          {
            "name": "",
            "label": this.$t('AllCategories')
          },
          { "name": "VERSIONS",
            "label": "版本"
          },
          { "name": "CREDENTIALS",
            "label": "認證"
          },
          { "name": "LOCATIONS",
            "label": "站點"
          },
          { "name": "SESSIONS",
            "label": "充電會話"
          },
          { "name": "CDRS",
            "label": "充電交易明細"
          },
          { "name": "TARIFFS",
            "label": "費率"
          },
          { "name": "TOKENS",
            "label": "用戶權杖"
          },
          { "name": "COMMANDS",
            "label": "控制訊息"
          }
        ],
        roleOptions: [
          {
            "name": "",
            "label": this.$t('AllCategories')
          },
          { "name": "SENDER",
            "label": "發送請求"
          },
          { "name": "RECEIVER",
            "label": "接收請求"
          }
        ],
        paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
        reportDate: this.formatCurrentDateTime()
      };
    },
    watch: {
      paginationSelectedPage: {
        handler: function(newValue) {
          this.listQuery.page = newValue - 1
        }
      },
      'listQuery.serviceId': function () {
            this.listQuery.page = 0
            this.resetPage(this.getList)
      },
      'listQuery.name': function() {
        this.listQuery.page = 0
        this.resetPage(this.getList)
      },
      'listQuery.module': function() {
        this.listQuery.page = 0
        this.resetPage(this.getList)
      },
      'listQuery.role': function() {
        this.listQuery.page = 0
        this.resetPage(this.getList)
      },
      dateRange: function(val) {
        if (val) {
          let sdt = this.$moment(this.dateRange[0]).tz('Asia/Taipei').format() // 轉為台灣時區
          let edt = this.$moment(this.dateRange[1]).tz('Asia/Taipei').format() // 轉為台灣時區
          sdt = sdt.substring(0,19) + 'Z'
          edt = edt.substring(0,19) + 'Z'
          console.log("change dateRange: " + sdt + " - " + edt)
          this.listQuery.startdate = sdt
          this.listQuery.enddate = edt
          this.resetPage(this.getList)
        } else {
          this.listQuery.startdate = undefined
          this.listQuery.enddate = undefined
          // 未輸入
          this.$message({
              message: "請輸入時間範圍",
                type: 'error'
          })
        }
      },
    },
    async created() {
      let date = new Date()
      let oneDayAgo = new Date()
      oneDayAgo = oneDayAgo.setDate(oneDayAgo.getDate()-1)
      this.dateRange = [oneDayAgo, date]

      this.dialogWidth = this.setDialogWidth()
      this.getServiceOptions()
      
      //上面設定日期後觸發 getList 動作
      //this.resetPage(this.getList)
    },
    beforeDestroy(){
      console.log("call beforeDestroy");
      //stopRefreshToken();
    },
    mounted() {
      window.onresize = () => {
        return (() => {
          this.dialogWidth = this.setDialogWidth()
        })()
      }
    },
    methods: {
      getList() {       
        if(this.listQuery.startdate != undefined && this.listQuery.enddate != undefined) {
          this.listLoading = true
          this.axios.get('/api/v1/ocpi/logs', {params: this.listQuery}).then(res=> {
            // 處理要顯示的範圍 (sort)
            this.logs = Array.from(res.data.content);
            this.logs.sort(function(a, b){return b.timestamp - a.timestamp})
            /*
            srclist.sort(function(a, b){
              if (a.type > b.type) {
                return -1;
              }
              if (b.type > a.type) {
                return 1;
              }
              return 0;})*/
              this.list = []
              for(let i=0,  p = this.listQuery.page*this.listQuery.size; p < (this.listQuery.page+1)*this.listQuery.size && p < res.data.totalElements; p++, i++ ) {
                this.list[i] = this.logs[p]
              }
            //this.list = displaylist
            this.total = res.data.totalElements

            // 如果回覆的查詢筆數超過5000筆(max_entries_limit_per_query)，提醒用戶縮小查詢範圍 (並給予回傳的查詢時間範圍資訊)
            let max_entries_limit_per_query = 5000
            if(this.total == max_entries_limit_per_query) {
              this.$alert('查詢資料筆數超過最大允許回傳筆數('+max_entries_limit_per_query+')，本次查詢只回傳('
                + this.parseDateTimeToHHMMSS(this.list[0]["timestamp"]) +' ~ '+ this.parseDateTimeToHHMMSS(this.list[this.list.length-1]["timestamp"]) 
                +')時間範圍內資料，請調整查詢時間範圍', this.$t('Hint'), {
                confirmButtonText: 'OK',
                
              })
            }

            this.listLoading = false

            this.reportDate = this.formatCurrentDateTime()
          }).catch(err => {
            console.log('err:' + err)
            //console.log('err:' + err.status)
            if(err.status == 400) {  // 用來顯示 loki api 回傳的錯誤訊息
              this.$message({
                message: err.data,
                duration: 5000,
                showClose: true,
                type: 'error'
              })
            }
          })
        } else {
          this.$message({
              message: "請輸入時間範圍",
                type: 'error'
          })
        }
      },
      getServiceOptions() {
        this.listLoading = true
        this.axios.get('/api/v1/common/service/enabled').then(res => {
            this.serviceList = res.data
        })
      },
      logTableSortChange(column) { // 自定義排序方式
        this.listLoading = true
        this.list = []
        if (column.prop.indexOf('profile.') != -1) {
          column.prop = column.prop.replace('profile.','')
        }

        //console.log("logTableSortChange: " + column.prop);
        if (column.order === 'descending') {
          if(column.prop === 'timestamp') {
           
            this.logs.sort(function(a, b){return b.timestamp - a.timestamp})
            //console.log(this.logs.length);
          } else {
            this.logs.sort(function(a, b){
              if (a[column.prop] > b[column.prop]) {
                return -1;
              }
              if (b[column.prop] > a[column.prop]) {
                return 1;
              }
              return 0;
            })
          }
          //this.listQuery.sort = column.prop + ',' + 'DESC'
        } else {
          if(column.prop === 'timestamp') {
            
            this.logs.sort(function(a, b){return a.timestamp - b.timestamp})
          } else {
            this.logs.sort(function(a, b){
              if (a[column.prop] > b[column.prop]) {
                return 1;
              }
              if (b[column.prop] > a[column.prop]) {
                return -1;
              }
              return 0;
            })
          }

          
        }
        console.log(this.logs.length);
          for(let i=0,  p = this.listQuery.page*this.listQuery.size; p < (this.listQuery.page+1)*this.listQuery.size && p < this.total; p++, i++ ) {
            this.list[i] = this.logs[p]
          }
          this.listLoading = false
      },
      getServiceName(sid) {
        let name = "系統"
        this.serviceList.forEach(item => {
          if(item.id == sid) {
            name= item.name
          }})
        return name
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  
  </style>
  