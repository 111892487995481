import { render, staticRenderFns } from "./Cooperation.vue?vue&type=template&id=069d4799&scoped=true"
import script from "./Cooperation.vue?vue&type=script&lang=js"
export * from "./Cooperation.vue?vue&type=script&lang=js"
import style0 from "./Cooperation.vue?vue&type=style&index=0&id=069d4799&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "069d4799",
  null
  
)

export default component.exports