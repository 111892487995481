<template>
  <el-dialog width="600" title="系統API-詳細資訊設定" :visible="dialogVisible" @close="dialogDataOnClose" @open="dialogDataOnOpen()">
    <div style="text-align: center">
      <el-form ref="dataForm" :rules="rules" :model="updateQuery" size="small" label-width="100px">
        <el-row :gutter="20">
          <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
            <el-form-item prop="apiVersion" label="版本號碼" >
              <el-input id="apiVersion" size="small"  v-model="updateQuery.apiVersion" :disabled=true placeholder="最多3階表示,例如:2.2.1" class="filter-item"
                        clearable style="width: 95%"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
            <el-form-item prop="identifier" label="模組ID" >
              <el-select id="role" v-model="updateQuery.identifier" placeholder="請選擇模組名稱" :disabled="isModify" size="small" class="filter-item" style="width: 95%" >
                <el-option v-for="(item, index) in ocpiData.identifier" :key='index' :value="item">
                  {{item}}
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
            <el-form-item prop="role" label="角色" >
              <el-select id="role" v-model="updateQuery.role" placeholder="請選擇角色" :disabled="isModify" size="small" class="filter-item" style="width: 95%" >
                <el-option v-for="(item, index) in ocpiData.role" :key='index' :value="item">
                  {{item}}
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">
            <el-form-item  prop="url" label="API URL">
              <el-input id="url" size="small"  v-model="updateQuery.url" placeholder="例如:https://example.com.tw/ocpi/2.2.1" class="filter-item"
                        clearable style="width: 95%">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
    </div>
    <br/><br/>
    <el-button style="margin-bottom: 0px; margin-top: 10px;" size="median" type="primary"  @click="updateData()">{{$t('Save')}}</el-button>

    <br/><br/>
  </el-dialog>

</template>

<script>
export default {
  name: "SystemApiDetailSetting",
  props: { //一個元件需要明確地宣告它所接受的props，這樣Vue 才能知道外部傳入的哪些是props
    apiDetailData: {
      require: false,
      default: undefined,
      type: Object
    },
    dialogVisible: {
      require: true,
      default: undefined,
      type: Boolean
    },
    isModify: {
      require: true,
      default: undefined,
      type: Boolean
    }
  },
  data() {

    let apiVersion = (rule, value, callback) =>{
      console.log(value)
      if(/^(?:(\d+))(?:\.(\d+))?(?:\.(\*|\d+))?$/.test(value)){
        callback();
      }else{
        callback(new Error(this.$t('IncorrectCode')));
      }

    };

    let url = (rule, value, callback) =>{
      console.log(value)
      if(/^(?:^|\s)((https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(:\d+)?(\/\S*)?)$/.test(value)){
        callback();
      }else{
        callback(new Error(this.$t('IncorrectCode')));
      }

    };

    return {
      data: [],   //el-transfer的資料輸入,array[object]
      value: [],  //el-transfer的被選取資料的index, array[int]
      service: {  //接收prop serviceData值
        id:undefined,
        name:undefined,
        code:undefined,
        description:undefined,
        enabledOCPI:undefined,
        ocpiPartyId: undefined,
        ocpiCountryCode: undefined,
        ocpiVersionId: undefined,
        partnerCPOs:[],
        partnerEMSPs:[],
        exclude_partnerCPOs:[],
        exclude_partnerEMSPs:[],
      },
      ocpiData: {
        identifier:['cdrs','chargingprofiles','commands','credentials','hubclientinfo','locations','sessions','tariffs','tokens'],
        role:['SENDER','RECEIVER']
      },
      updateQuery: {
        id:undefined,
        apiVersion: '2.2.1',
        identifier: 'cdrs',
        role: "SENDER",
        url: undefined,
        updateTime: undefined
      },
      allCPOs:[], //service的所有CPOs,由partnerCPOs+exclude_partnerCPOs合成,
      rules: {
        apiVersion: [{ required: true, validator: apiVersion, trigger: 'blur' }] ,
        url: [{ required: true,  message: '('+this.$t('Required')+')', trigger: 'blur' }] ,
        identifier: [{ required: true,  message: '('+this.$t('Required')+')', trigger: 'blur' }],
        role: [{ required: true,  message: '('+this.$t('Required')+')', trigger: 'blur' }],
      },
    };
  },
  watch: {
    apiDetailData(val) {
      if (val) {
        console.log(val);
      }else{
        console.log(val);
      }
    }
  },
  methods: {
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          // update
          this.axios.put('/api/v1/ocpi/api/system/version-detail', this.updateQuery).then(() => {
            this.$message({
              showClose: true,
              message: this.$t('SuccessEdited'),
              type: 'success'
            })
            this.dialogDataOnClose()
          }).catch(err => {
            console.log('err:' + err)
            this.$message({
              message: this.$t('FailEdited') + ' ('+err.data.msg+')' ,
              type: 'error'
            })
          })
        } else {
          this.$message({
            message: this.$t('IncorrectFormat'),
            type: 'error'
          })
        }
      })
    },
    dialogDataOnClose() {
      this.resetUpdateQuery();
      this.$refs.dataForm.resetFields() // 表單校驗結果重置
      this.$emit('close') // 觸發父層 close
    },
    dialogDataOnOpen(){
      let vm = this;

      this.updateQuery.id = this.apiDetailData.id;
      this.updateQuery.apiVersion = this.apiDetailData.apiVersion;
      this.updateQuery.identifier = this.apiDetailData.identifier;
      this.updateQuery.role = this.apiDetailData.role;
      this.updateQuery.url = this.apiDetailData.url;
      this.updateQuery.updateTime = this.apiDetailData.updateTime;

      console.log(this.updateQuery);
    },
    resetUpdateQuery() {
      this.updateQuery = {
        id:undefined,
        apiVersion: '2.2.1',
        identifier: 'cdrs',
        role: "SENDER",
        url: undefined,
        updateTime: undefined
      }
    },
  },
  created() {

  }
}
</script>

<style scoped>

</style>
