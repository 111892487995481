import elemneten from 'element-ui/lib/locale/lang/en'
import elemnetja from 'element-ui/lib/locale/lang/ja'
import elemnettw from 'element-ui/lib/locale/lang/zh-TW'
import Vue from 'vue'
import VueI18n from 'vue-i18n'


Vue.use(VueI18n)

const locale = 'tw'

const messages = {
    //主項目採取大寫、子項目採取小寫
    tw: {
        ...elemnettw,   //elementUI語系繁體中文object

        /* 比較常重複使用之共用部分 */
        CPMS            : '充電服務管理系統',
        UseLang         : '語言',
        Account         : '帳號',
        Status          : '狀態',
        Date            : '日期',
        Yes             : '是',
        No              : '否',
        Enable          : '啟用',
        Disable         : '停用',
        Filter          : '過濾',
        FilterConditions: '@:Filter條件',
        Settings        : '設定',
        Edit            : '編輯',
        Role            : '角色',
        Update          : '更新',
        Add             : '新增',
        Create          : '建立',
        Delete          : '刪除',
        Confirm         : '確認',
        Cancel          : '取消',
        Success         : '成功',
        Fail            : '失敗',
        All             : '全部',
        Save            : '儲存',
        Input           : '輸入',
        Output          : '輸出',
        Charging        : '充電',
        ChargingStation : '充電站',
        ChargingPoint   : '充電樁',
        ChargingConnector:'充電槍',
        ParkingSpace    : '停車位',
        Power           : '功率',
        Error           : '錯誤',
        Query           : '查詢',
        Remote          : '遠端',
        Trigger         : '觸發',
        Termination     : '終止',
        Start           : '起始',
        End             : '結束',
        Time            : '時間',
        Email           : '電子郵件',
        Maximum         : '最大',
        Minimum         : '最小',
        Information     : '資訊',
        Gender          : '性別',
        BindCard        : '支付',
        Range           : '範圍',
        And             : '及',
        Detail          : '明細',
        SampleTime      : '取樣間隔',
        MultipleChoice  : '可複選',
        Floor           : '樓層',

        /* ~/layout/component/Header.vue */
        Logout          : '登出',
        YouHaveLoggedOut: '您已登出',
        Hint            : '提示',
        ConfirmLogout   : '確定要@:Logout嗎',

        /* ~/utils/api.js、auth.js */
        LoginAgain      : '請重新登入',
        NoSuchPermission: '無此權限',

        /* ~/view/alert/record.vue  */
        ChargingStationS: '充電站選擇',

        /* ~/view/alert/setting.vue  */
        Operate         : '操作',

        /* ~/view/dashboard/Dashboard.vue  */
        Overview        : '總攬',
        Amount          : '金額',
        Total           : '總數',
        TotalAmount     : '總@:Amount',
        Unit            : '個',
        ConsumptionAmount: '當月消費@:Amount',
        Yuan            : '元',
        TotalPowerUsage : '總用電量',
        TotalPowerUsageM: '當月總用電量',
        Degree          : '度',
        AlertInformation: '@:Alert訊息',
        AlertInformationT:'今日@:Alert訊息',
        Quantity        : '數量',
        ChargingNow     : '充電中',
        Standby         : '待機中',
        Offline         : '離線中',
        ChargingNowNum  : '充電樁在充電中的總數',
        StandbyNum      : '充電槍待機的總數',
        OfflineNum      : '離線中的總數',
        ErrorsOccurred  : '發生@:Error',
        ErrorsOccurredNum: '發生@:Error的總數',

        /* ~/view/dashboard/control.vue  */
        ChargingPointS  : '充電樁選擇',
        ChargingConnectorS:'充電槍選擇',
        RemoteTriggerCP : '@:Remote@:Trigger充電流程',
        RemoteTerminationCP:'@:Remote@:Termination充電流程',
        Currently       : '目前',
        Current         : '電流',
        Progress        : '進度',
        Accumulation    : '累積',
        NotYetObtained  : '尚未取得',

        /* ~/view/login/index.vue  */
        Login_Title     : '充電服務管理系統',
        Login           : '登入',
        Logged_in       : '已登入',
        Username        : '使用者',
        Password        : '密碼',
        Captcha         : '圖形驗證碼',
        CaptchaGen      : '點擊更新驗證碼',
        Required        : '必須',
        Username_req    : '@:Account是必須要填的',
        Username_length : '@:Account長度至少要{n}個字以上',
        Password_req    : '@:Password是必須要填的',
        Password_length : '@:Password長度至少要{n}個字以上',
        Complete_info   : '請將資料填寫完整',

        /* ~/view/report/Station.vue  */
        VoltageV        : '電壓(V)',
        CurrentA        : '電流(A)',
        PowerW          : '功率(W)',
        Meter           : '電表',
        MeterDegree     : '電表(Wh)',
        IntervalDegree  : '區間度(Wh)',
        ChargingProgress: '@:Charging@:Progress(%)',
        ExportReport    : '匯出報表',
        DownloadThisPageCsv: '匯出本頁(CSV)',
        DownloadAllDataCsv:  '報表下載(csv)',
        DownloadAllDataXls:  '報表下載(xls)',
        DownloadAllDataXlsx: '報表下載(xlsx)',

        /* ~/view/report/Status.vue  */
        StartDegree     : '充電起始度數(Wh)',
        EndDegree       : '充電結束度數(Wh)',
        EndReasonId     : '結束原因代號',

        /* ~/view/report/Carbon.vue  */
        ReportProductionTime: '報表產製時間',
        CarbonFactor    : '碳排係數',
        CarbonNumber    : '碳排量',
        CarbonPercentage: '碳排比',

        /* ~/view/station/Point.vue */
        List            : '列表',
        Connect         : '連線',
        OCPPVersion     : 'OCPP版本',
        ChargingConnectorNum: '@:ChargingConnector數量',
        Install         : '供裝',

        /* ~/view/station/PointSetting.vue */
        PowerSupply     : '電源',
        Longitude       : '經度',
        Latitude        : '緯度',
        RatedOutputPower: '額定輸出功率',
        Number          : '編號',
        PlugType        : '插頭型態',
        Rate            : '費率(元/度)',
        Package         : '費率包',
        OCPPVerSelect   : 'OCPP版本',
        OCPPSix         : 'ocpp1.6',
        OCPPNon         : '非OCPP版本',    

        /* ~/view/station/Station.vue */
        Addstation      : '新建充電站',
        Name            : '名稱',
        RaSystemServiceName     : 'RA系統服務名稱',
        RaSystemServiceType     : 'RA系統服務型態',
        AccountingContractNumber: '會計契約編號',
        Code            : '代碼',
        Operator        : '營運商',
        Location        : '位置',
        EnableStatus    : '啟用狀態',
        ExternalServices: '對外服務',

        /* ~/view/station/StationSetting.vue */
        CountyCity      : '縣市',
        TaipeiCity      : '台北市',
        NewTaipeiCity   : '新北市',
        TaoyuanCity     : '桃園市',
        TaichungCity    : '台中市',
        TainanCity      : '台南市',
        KaohsiungCity   : '高雄市',
        HsinchuCounty   : '新竹縣',
        MiaoliCounty    : '苗栗縣',
        ChanghuaCounty  : '彰化縣',
        NantouCounty    : '南投縣',
        YunlinCounty    : '雲林縣',
        ChiayiCounty    : '嘉義縣',
        PingtungCounty  : '屏東縣',
        YilanCounty     : '宜蘭縣',
        HualienCounty   : '花蓮縣',
        TaitungCounty   : '台東縣',
        Address         : '地址',  //1030 加入

        /* ~/view/station/Meter.vue */
        Controller      : '控制器',
        Device          : '設備',
        Sensor          : '感測器',
        Path            : '路徑',
        OcppScType      : '動態均流',
        Max             : '最大',
        Control         : '控管',
        NotControl      : '不需控管',
        Tag             : '監控點',
        TagAddress      : '暫存器位址',
        DataLength      : '資料長度',
        DataType        : '資料型別',
        ModelType       : '暫存器記憶體型別',
        StandardTagName : '標準監控點名稱',
        TagUnit         : '單位',

        /* ~/view/station/ParkingSpace.vue */
        ParkingType     : '車位型態',

        /* ~/view/activity/Announcement.vue */
        Announcement    : '公告',
        Publish         : '發布',
        Title           : '標題',
        Discount        : '優惠',

        /* ~/view/system/Account.vue */
        CreateAccount   : '新建@:Account',
        EnabledStatus   : '@:Enable@:Status',
        Enabled         : '已@:Enable',
        NotEnabled      : '未@:Enable',
        RolePermissions : '@:Role權限',
        ConfirmDeleteAccount: "確定@:Delete@:Account",
        SuccessDeleted  : '@Delete@Sucess',
        FailDeleted     : '@Delete@Fail',

        /* ~/view/system/AccountRoleSetting.vue */
        RoleSettings    : '@:RolePermissions @Settings',
        SuccessEdited   : '編輯@:Success',
        FailEdited      : '編輯@:Fail',

        /* ~/view/system/AccountSetting.vue */
        AccountSettings : '@:Account@:Settings',
        EnterAdminAccount: '輸入管理@:Account',
        EnterPassword   : '輸入@:Password',
        YourName        : '姓名',
        InputYourName   : '@:Input@:YourName',
        TelePhone       : '電話',
        InputTelephoneNum: '@:Input@:TelePhone號碼',
        WhetherToEnable: '是否啟用',
        IncorrectAccount: '@:Account格式不正確,@:Account為信箱格式',
        DifferentPassword:'與@:Password不相同',
        IncorrectFormat : '格式不正確',
        AccountSettingsNote : '註: 無須變更密碼時，請勿輸入密碼欄位',

        /* ~/view/system/Role.vue */
        DirectoryPermission: '目錄權限',
		DirectoryPermissionSetting: '目錄權限@:Settings',
        DirectorySettingToolTip: '選擇指定角色設定目錄權限',
        CreateRole         : '新增@:Role',
        RoleList           : '@:Role@:List',
        UpdateDate         : '@:Update@:Date',
        CreateDate         : '@:Create@:Date',
        AddRole            : '@:Add@:Role',
        EditRole           : '@:Edit@:Role',
        RoleName           : '@:Role@:Name',

        /* ~/view/system/Service.vue */
        SelectAnOperator: '選擇一個營運商',
        AllOperator     : '@:All@:Operator',
        CreateOperator  : '新建@:Operator',
        OperatorCode    : '@:Operator@:Code',
        OperatorName    : '@:Operator名稱',
        QueryNameOrCode : '查詢營運商名稱或代碼',
        Company         : '公司',
        CompanyName     : '公司名稱',
        Contact         : '聯絡人',
        ConfirmDeleteOperator: "@:Confirm@:Delete@:Operator",
        OCPIEnabledStatus : "OCPI@:EnableStatus",

        /* ~/view/system/ServiceSetting.vue */
        OperatorSettings : '@:Operator@:Settings',
        CompanyEngName  : '公司英文名稱',
        ContactMail: '公司信箱',
        ContactPhone: '公司電話',
        ContactAddress: '公司地址',
        Ban: '統一編號',
        CompanyWebUrl: '官網網址',
        CompanyLogoUrl: 'Logo網址',
        CompanyTsAndCsURL: '服務條款網址',
        EnterOperatorName: '@:Input@:OperatorName',
        EnterOperatorCode: '@:Input@:OperatorCode',
        EnterCompanyName: '@:Input@:CompanyName',
        EnterCompanyEngName: '@:Input@:CompanyEngName',
        EnterContact: '@:Input@:Contact',
        EnterContactPhone: '@:Input@:ContactPhone',
        EnterContactAddress: '@:Input@:ContactAddress',
        EnterContactMail: '@:Input@:ContactMail',
        EnterBan: '@:Input@:Company@:Ban',
        EnterCompanyWebUrl: '@:Input@:Company@:CompanyWebUrl',
        EnterCompanyLogoUrl: '@:Input@:Company@:CompanyLogoUrl',
        EnterCompanyTsAndCsURL: '@:Input@:Company@:CompanyTsAndCsURL',
        ServiceSettingNote: '註: 營運商代碼格式為大寫英文(A-Z)數字(0-9)與符號(_-)，長度至少為3',
        IncorrectMail: '@:Email格式不正確',
        IncorrectCode: '@:Code格式不正確',
        OCPIPartyId: '成員代碼',
        EnterOCPIPartyID: '@:Input@:Operator Party ID',
        OCPICountryCode: '國家代碼',
        EnterOCPICountryCode: '@:Input@:Operator Country Code',
        OCPIVersionId: '版本號碼',
        EnterOCPIVersionId: '@:Input@:OCPIVersionId',
        OCPIVersionEndPointUrl: '版本資訊點網址',
        EnterOCPIVersionEndPointUrl: '@:Input@:OCPIVersionEndPointUrl',
        OCPICredentialToken: '驗證碼',
        EnterOCPICredentialToken: '@:Input@:OCPICredentialToken',
        OCPIPartnerCredentialToken: '夥伴驗證碼',
        EnterOCPIPartnerCredentialToken: '@:Input@:OCPIPartnerCredentialToken',
        WrongK2Code: 'K2會計契約碼錯誤', //Added by Lillian (20241024)

        /* ~/view/trade/Record.vue */
        Member          : '會員',
        TradeType       : '交易方式',
        TradeAmount     : '交易金額',
        TradeTime       : '交易時間',
        TradeStatus     : '交易狀態',
        TradePlatformId : '金流平台交易編號',
        ChargingSessionId : '充電辨識碼',
        ChargingTime    : '充電時間',
        ChargingMeter   : '充電度數',
        AllChargingStation  : '@:All@:ChargingStation',
        AllCategories   : '所有類別',
        CreditCard    : '信用卡',
        DownloadAllRecordCsv:  '交易記錄下載(csv)',
        DownloadAllRecordXls:  '交易記錄下載(xls)',
        DownloadAllRecordXlsx: '交易記錄下載(xlsx)',
      


        /* ~/view/trade/RecordDetail.vue */
        RefundFail    : '退款失敗，請聯繫系統管理員',
        NotAdmin    : '無權限執行此功能',
        OldRecord   : '舊紀錄無法執行',

        /* ~/trade/Charging.vue */
        OperatorType   : '操作類別',
        ChargingStatus : '充電狀態',
        DataSource:      '資料來源',
        TradeId        : '交易編號',

        /* ~/trade/Rfid.vue */
        CardNumber   : '卡號',

        /* ~/application/ApiKey.vue */
        ApiKeyHeader        : '金鑰( Headers: X-API-Key={X_API_KEY} )',
        Description         : '描述',
        CreateApiKey        : '新增API金鑰',
        RegisterApiService  : '註冊API服務',
        DeleteApiKey        : '刪除API金鑰',

        /* ~/application/ApiKeySetting.vue */
        EditApiKey          : '設定API金鑰',
        ApiService          : 'API服務',
        EnterApiKeyDescription: '@:Input金鑰描述',
        ApiServiceEndpoint: 'API URL',
        ApiServiceName: '服務名稱',
        ApiServiceDescription: '服務描述',

        /* ~/pay/Tappay.vue */
        TappayAddAccount    : '新增Tappay資訊',
        TappayPartnerKey    : '帳戶金鑰',
        TappayMerchantId    : '信用卡收單銀行碼',
        TappayMerchantIDofLinePay : 'Line Pay收單銀行碼',
        InvoiceCompanyName  : '開立發票之公司名稱',
        InvoiceCompanyTaxId : '開立發票之公司統編',
        //DuplicatedTappayAccount: '您已建立收單銀行資訊(一個營運商只允許設定一組收單銀行資訊)',
        DuplicatedTappayAccount: '您已建立收單銀行資訊(一個營運商只允許設定一組帳戶金鑰及收單銀行碼)', //Modified by Lillian (20240412)
        ConfirmDeleteTappay: "確定@:DeleteTappay資訊",
        IsInvoiceServiceApplied: '已開通雲端發票服務',
        IsSetServerIP       : '已設定後台IP',
        IsAppRegistered     : '已註冊App',
        InvoiceServiceNote  : '[註] 已開通雲端發票服務: 已透過 Tappay Portal>電子發票>開通服務 之網頁完成加值中心營業人帳號註冊。',
        IPNote  : '[註] 已設定後台IP: 已透過 Tappay Portal>開發人員內容>系統設定 之網頁完成後台IP限制設定。',
        AppNote  : '[註] 已註冊App: 已透過 Tappay Portal>開發人員內容>應用程式 之網頁完成加值中心營業人帳號註冊。',
        PartnerKeyNote : '[註] 帳戶金鑰: 請透過 Tappay Portal>公司資訊 之網頁取得 Partner Key 設定值後填入此欄位。此資料設定後無法再變更。',
        MerchantIdNote : '[註] 收單銀行碼: 請透過 Tappay Portal>商家管理>商家設置 之網頁取得 Merchant ID 設定值後填入此欄位。',
        PartnerAccount : 'Tappay網站登入帳號',

        /* ~/pay/TappaySetting.vue */
        TappayAccountSetting : 'Tappay 帳戶設定',
        EnterTappayPartnerKey : '@:Input@:TappayPartnerKey',
        EnterTappayMerchantId : '@:Input@:TappayMerchantId',
        EnterTappayMerchantIDofLinePay : '@:Input@:TappayMerchantIDofLinePay',
        //EnterInvoiceAccountId : '@:Input@:InvoiceAccountId',
        //EnterInvoiceSharedSecret : '@:Input@:InvoiceSharedSecret',
        //EnterInvoiceCompanyName : '@:Input@:InvoiceCompanyName',
        //EnterInvoiceCompanyTaxId : '@:Input@:InvoiceCompanyTaxId',
        IncorrectInput       : '輸入欄位格式不正確',
        ExistKey             : '已存在相同帳戶金鑰',
        ExistService         : '已存在相同營運商',
        SameTappaySetting      : '已存在相同設定',
        EnterPartnerAccount : '@:Input@:PartnerAccount',

		/* ~/user/User.vue */
        MemberSetting       : '@:Member@:Settings',
        MemberAccount       : '@:Member@:Account',
        MemberName          : '@:Member@:YourName',
        MemberSettingNote   : '註: 「啟用狀態」若設為[停用]，使用者將無法從APP存取任何網站資訊(也無法登入APP)，類似設定「黑名單」效果，設為[啟用]則恢復正常。',
        MemberSettingDeleteNote : '註: [刪除會員]會將「啟用狀態」設為[停用]並標註時間，依核帳要求，資料會保留2年不會立即刪除，超過時間系統會自動刪除，設為[啟用]則恢復正常。',

        /* ~/resource/config/menu.json 、 ~/router/index.vue 、  */
        DashBoard       : '營運看板',
        ITArea          : '資分專區' ,
        PaymentIssue    : '止付爭議事件',
        TappayAccounting: 'Tappay入帳',
        Watch           : '充電站總覽',
        DB              : {
            station        : '充電站總覽',
            live_info      : '充電站即時資訊',
            cp             : '充電樁設備@:Status',
            graphic        : '充電站圖控管理',
            map            : '地圖模式',
            control        : '充電槍控制',
            live_status    : '即時@:Status'
        },
        Report          :'統計報表',
        AccountingReport :'核帳報表',
        OperatingReport  :'營運報表',
        ARp             : {
            invoice        : '請款紀錄',
            refund         : '退款紀錄',
            difference     : '每月差額',
            todolist       : '待辦事項'
        },
        ORp             : {
            counting_record : '充電服務帳務紀錄',

        },
        Rp              : {
            station        : '充電站充電@:Report',
            status         : '充電樁@:Status@:Report',
            carbon_emmision: '碳排放@:Report',
            usage_record : '充電樁使用率@:Report',
            equipment_availability : '設備妥善率@:Report',
            popular_rate: '熱門使用率@:Report'
        },
        Alert           :'告警管理',
        Ar                 : {
            setting        : '發送對象設定',
            record         : '告警紀錄',
            event          : '告警設定',
            exceed         : '超約@:Alert'
        },
        Dispatching     :'派工(ITRIS)',
        Dp              : {
            failure_report : '故障報修',
            pending        : '待處理單',
            progress_query : '處理單查詢',
            setting        : '派工設定',
            recording      : '客服錄音管理'
        },
        Maintenance     : '維運',
        Mt              : {
            analysis       : '統計分析',
            report         : '維護報表',
            network        : '網路連線查測',
            firmware_update: '充電樁韌體更新'
        },
        ChargingStationM : '充電站點管理',
        CS              : {
            basic          : '充電站基本資料',
            station        : '充電站建檔',
            point          : '充電樁建檔',
            connector      : '充電槍建檔',
            parkingspace   : '充電站停車位建檔',
            parkingspacebinding   : '充電站停車位綁定',
            meterIoT       : 'IoT電表設定',
            meter          : '電表設定',
            rfid_setting   : '卡片設定',
            qrcode         : 'QRCode產生與管理'
        },
        Energy          : '能源管理',
        En              : {
            schedule       : '排程充電',
            dynamic        : '動態均流充電',
            distribute     : '電能分配控制',
            usagerecord    : '用電紀錄',
            metercontract  : '電表契約設定',
            package        : '費率包'
        },
        Fee             : '費率設定',
        Fe              : {
            chargingpoint : '充電樁費率設定',
        },
        Payment         : '會員服務',
        Pay             : {
            cloudinvoice  : '雲端電子發票',
            tappay        : '支付介接設定',
            user          : '會員管理',
            tradingrecord : '交易紀錄',
            chargingrecord: '充電紀錄',
            rfidrecord: '卡片交易紀錄',
            todolist:   '待辦事項'
        },
        Application     :'應用管理設定',
        Ap              : {
            api_management: 'API金鑰設定',
            api_key       : 'API金鑰管理',
            push          : '會員推播通知',
        },
        System          : '系統設定',
        Sys             : {
            profile       : '個人資料維護',
            logs          : '系統日誌',
            rule          : '角色權限設定',
            account       : '使用者帳號',
            service       : '營運商建檔'
        },
        Activity        :'活動資訊',
        Act             : {
            announcement  : '最新公告',
            discount      : '優惠訊息',
            maintenance   : '維護訊息'
        },

        OcpiManage      : 'OCPI',
        Ocpi            :{
            token       : '通行證管理',
            tokenconfig : '通行證設定',
            cooperation : '合作關係',
            api_management : 'API管理',
            command : '控制紀錄',
            cdr: '充電明細',
            logs: 'API存取紀錄',
        },

        Business        :'營業資訊',
        Bs             : {
            title  : '營業資訊設定'

        },

    },
    en: {
        ...elemneten,

        /* 比較常重複使用之共用部分 */
        CPMS            : 'Charging Service Management',
        UseLang         : 'Language',
        Account         : 'Account',
        Status          : 'Status',
        Date            : 'Date',
        Yes             : 'Yes',
        No              : 'No',
        Enable          : 'Enable',
        Disable         : 'Disable',
        Filter          : 'Filter',
        FilterConditions: '@:Filter Conditions',
        Settings        : 'Settings',
        Edit            : 'Edit',
        Role            : 'Role',
        Update          : 'Update',
        Add             : 'Add',
        Create          : 'Create',
        Delete          : 'Delete',
        Confirm         : 'Confirm',
        Cancel          : 'Cancel',
        Success         : 'Success',
        Fail            : 'Fail',
        All             : 'All',
        Save            : 'Save',
        Input           : 'Input',
        Output          : 'Output',
        Charging        : 'Charging',
        ChargingStation : 'Charging Station',
        ChargingPoint   : 'Charging Point',
        ChargingConnector:'Charging Connector',
        ParkingSpace    : 'Parking Space',
        Power           : 'Power',
        Error           : 'Error',
        Query           : 'Query',
        Remote          : 'Remote',
        Trigger         : 'Trigger',
        Termination     : 'Termination',
        Start           : 'Start',
        End             : 'End',
        Time            : 'Time',
        Email           : 'Email',
        Maximum         : 'Max',
        Minimum         : 'Min',
        Information     : 'Information',
        Gender          : 'Gender',
        BindCard        : 'Payment',
        Range           : 'Range',
        And             : 'And',
        Detail          : 'Detail',
        SampleTime      : 'Sample Interval',
        MultipleChoice  : 'Multiple',
        Floor           : 'Floor',

        /* ~/layout/component/Header.vue */
        Logout          : 'Logout',
        YouHaveLoggedOut: 'You have Logged Out',
        Hint            : 'Hint',
        ConfirmLogout   : 'Are you sure you want to @:Logout?',

        /* ~/utils/api.js、auth.js */
        LoginAgain      : 'Please Login Again',
        NoSuchPermission: 'No Such Permission',

        /* ~/view/alert/record.vue  */
        ChargingStationS: 'ChargingStation',

        /* ~/view/alert/setting.vue  */
        Operate         : 'Operate',

        /* ~/view/dashboard/Dashboard.vue  */
        Overview        : 'Overview',
        Amount          : 'Amount',
        Total           : 'Total',
        TotalAmount     : '@:Total @:Amount',
        Unit            : 'Unit',
        ConsumptionAmount:'Spending Amount(This month)',
        Yuan            : 'Yuan',
        TotalPowerUsage : 'TotalPowerUsage',
        TotalPowerUsageM: 'TotalPowerUsage(This month)',
        Degree          : 'Degree',
        AlertInformation: '@:Alert Info',
        AlertInformationT:'@:Alert Info(Today)',
        Quantity        : 'Quantity',
        ChargingNow     : 'Charging',
        Standby         : 'Standby',
        Offline         : 'Offline',
        ChargingNowNum  : 'The total number of Charging Points being charged',
        StandbyNum      : 'The total number of Charging Connectors in Standby',
        OfflineNum      : 'Total number Offline',
        ErrorsOccurred  : 'Number of @:Error Occurred',
        ErrorsOccurredNum: 'Number of Errors that Occurred',

        /* ~/view/dashboard/control.vue  */
        ChargingPointS  : 'ChargingPoint',
        ChargingConnectorS:'ChargingConnector',
        RemoteTriggerCP : '@:Remote @:Trigger @:Charging Process',
        RemoteTerminationCP:'@:Remote @:Termination @:Charging Process',
        Currently       : 'Currently',
        Current         : 'Current',
        Progress        : 'Progress',
        Accumulation    : 'Accumulation',
        NotYetObtained  : 'Not Yet Obtained',

        /* ~/view/login/index.vue  */
        Login_Title     : 'CPS Management System',
        Login           : 'Login',
        Logged_in       : 'Logged in',
        Username        : 'Username',
        Password        : 'Password',
        Captcha         : 'Captcha',
        CaptchaGen      : 'Click to re-generate code',
        Required        : 'Required',
        Username_req    : '@:Account is @:Required',
        Username_length : '@:Account length should be at least {n} characters',
        Password_req    : '@:Password is @:Required',
        Password_length : '@:Password length should be at least {n} characters',
        Complete_info   : 'Please fill in the information completely',

        /* ~/view/report/Station.vue  */
        VoltageV        : 'Voltage(V)',
        CurrentA        : 'Current(A)',
        PowerW          : 'Power(W)',
        Meter           : 'Meter',
        MeterDegree     : 'Meter(Wh)',
        IntervalDegree  : 'Interval(Wh)',
        ChargingProgress: '@:Charging Progress(%)',
        ExportReport    : 'Export Report',
        DownloadThisPageCsv: 'ThisPage(CSV)',
        DownloadAllDataCsv:  'Report(csv)',
        DownloadAllDataXls:  'Report(xls)',
        DownloadAllDataXlsx: 'Report(xlsx)',

        /* ~/view/report/Status.vue  */
        StartDegree     : 'Start(Wh)',
        EndDegree       : 'End(Wh)',
        EndReasonId     : 'EndReason(id)',

        /* ~/view/report/Carbon.vue  */
        ReportProductionTime: 'Report Production Time',
        CarbonFactor    : 'Factor',
        CarbonNumber    : 'Carbon',
        CarbonPercentage: 'Percentage',

        /* ~/view/station/Point.vue */
        List            : 'List',
        Connect         : 'Connect',
        OCPPVersion     : 'OCPP Version',
        ChargingConnectorNum: 'The total number of @:ChargingConnector',
        Install         : 'Install',

        /* ~/view/station/PointSetting.vue */
        PowerSupply     : 'PowerSupply',
        Longitude       : 'Longitude',
        Latitude        : 'Latitude',
        RatedOutputPower: 'Rated Output Power',
        Number          : 'Number',
        PlugType        : 'Plug Type',
        Rate            : 'Rate(dollor/degree)',
        Package         : 'Package',

        /* ~/view/station/Station.vue */
        Addstation      : 'Add Charging Station',
        Name            : 'Name',
        RaSystemServiceName     : 'RA System Service Name',
        RaSystemServiceType     : 'RA System Service Type',
        AccountingContractNumber: 'Accounting Contract Number',
        Code            : 'Code',
        Operator        : 'Operator',
        Location        : 'Location',
        EnableStatus    : 'Enable Status',
        ExternalServices: 'External Services',
        CreatingDate    : 'Creating @:Date',

        /* ~/view/station/StationSetting.vue */
        CountyCity      : 'County|City',
        TaipeiCity      : 'Taipei',
        NewTaipeiCity   : 'NewTaipei',
        TaoyuanCity     : 'Taoyuan',
        TaichungCity    : 'Taichung',
        TainanCity      : 'Tainun',
        KaohsiungCity   : 'Kaohsiung',
        HsinchuCounty   : 'Hsinchu',
        MiaoliCounty    : 'Miaoli',
        ChanghuaCounty  : 'Changhua',
        NantouCounty    : 'Nantou',
        YunlinCounty    : 'Yunlin',
        ChiayiCounty    : 'Chiayi',
        PingtungCounty  : 'Pingtung',
        YilanCounty     : 'Yilan',
        HualienCounty   : 'Hualien',
        TaitungCounty   : 'Taitung',
        Address         : 'Address',

        /* ~/view/station/Meter.vue */
        Controller      : 'Controller',
        Device          : 'Device',
        Sensor          : 'Sensor',
        Path            : 'Path',
        OcppScType      : 'Dynamic Current Sharing',
        Max             : 'Max',
        Control         : 'Control',
        NotControl      : 'Not Control',
        Tag             : 'Tag',
        TagAddress      : 'Tag Address',
        DataLength      : 'Data Length',
        DataType        : 'Data Type',
        ModelType       : 'Model Type',
        StandardTagName : 'Standard Tag Name',
        TagUnit         : 'Unit',

        /* ~/view/station/ParkingSpace.vue */
        ParkingType     : 'Type',

        /* ~/view/activity/Announcement.vue */
        Announcement    : 'Announcement',
        Publish         : 'Publish',
        Title           : 'Title',
        Discount        : 'Discount',

        /* ~/view/system/Account.vue */
        CreateAccount   : 'Create @:Account',
        EnabledStatus   : '@:Enabled @:Status',
        Enabled         : 'Enabled',
        NotEnabled      : 'NotEnabled',
        RolePermissions : 'Permissions',
        ConfirmDeleteAccount: "@:Confirm to @:Delete @:Account?",
        SuccessDeleted  : 'Successfully Deleted',
        FailDeleted     : 'Failed to Deleted',

        /* ~/view/system/AccountRoleSetting.vue */
        RoleSettings    : '@:Role @Settings',
        SuccessEdited   : 'Edited successfully',
        FailEdited      : 'Edit failed',

        /* ~/view/system/AccountSetting.vue */
        AccountSettings : '@:Account @:Settings',
        EnterAdminAccount: 'Administrator @:Account',
        EnterPassword   : 'Enter @:Password',
        YourName        : 'Name',
        InputYourName   : 'Enter @:YourName',
        TelePhone       : 'TelePhone',
        InputTelephoneNum: 'Enter @:TelePhone Number',
        WhetherToEnable: 'Whether to enable',
        IncorrectAccount: 'Must be in email format',
        DifferentPassword:'Not the same as @:Password',
        IncorrectFormat : 'Input field format is incorrect',
        AccountSettingsNote : 'Note: Do not enter the password field if there is no need to change the password.',

        /* ~/view/system/Role.vue */
		DirectoryPermission: 'Directory Permission',
        DirectoryPermissionSetting: '@:DirectoryPermission @:Settings',
        DirectorySettingToolTip: 'Select the specified role to set directory permissions',
        CreateRole         : 'Create @:Role',
        RoleList           : '@:Role @:List',
        UpdateDate         : 'Updated Date',
        CreateDate         : 'Created Date',
        AddRole            : '@:Add @:Role',
        EditRole           : '@:Edit @:Role',
        RoleName           : '@:Role @:Name',

        /* ~/view/system/Service.vue */
        SelectAnOperator : 'Select an operator',
        AllOperator     : '@:All @:Operator',
        CreateOperator  : 'Create @:Operator',
        OperatorName    : '@:Operator name',
        OperatorCode    : '@:Operator @:Code',
        QueryNameOrCode : 'Query name or code',
        Company         : 'Company',
        CompanyName     : 'Company name',
        Contact         : 'Contact',
        ConfirmDeleteOperator: "@:Confirm to @:Delete @:Operator",

        /* ~/view/system/ServiceSetting.vue */
        OperatorSettings : '@:Operator @:Settings',
        CompanyEngName  : 'Company name (EN)',
        ContactMail: 'Company Email',
        ContactPhone: 'Company phone',
        ContactAddress: 'Company Address',
        Ban: 'Business ID number',
        CompanyWebUrl: 'Website Url',
        CompanyLogoUrl: 'Logo Url',
        CompanyTsAndCsURL: 'Terms of Service URL',
        EnterOperatorName: '@:Input @:OperatorName',
        EnterOperatorCode: '@:Input @:OperatorCode',
        EnterCompanyName: '@:Input @:CompanyName',
        EnterCompanyEngName: '@:Input @:companyEngName',
        EnterContact: '@:Input @:Contact',
        EnterContactPhone: '@:Input @:ContactPhone',
        EnterContactAddress: '@:Input @:ContactAddress',
        EnterContactMail: '@:Input @:ContactMail',
        EnterBan: '@:Input@:Company@:Ban',
        EnterCompanyWebUrl: '@:Input@:Company@:CompanyWebUrl',
        EnterCompanyLogoUrl: '@:Input@:Company@:CompanyLogoUrl',
        EnterCompanyTsAndCsURL: '@:Input@:Company@:CompanyTsAndCsURL',
        ServiceSettingNote: 'Note: The format of the operator code is uppercase English (A-Z) numbers (0-9) and symbols (_-), and the length is at least 3',
        IncorrectMail: '@:Email format is not correct',
        IncorrectCode: '@:Code format is not correct',


        /* ~/view/trade/Record.vue */
        Member          : 'Member',
        TradeType       : 'Trade Type',
        TradeAmount     : 'Trade Amount',
        TradeTime       : 'Trade Time',
        TradeStatus     : 'Trade Status',
        TradePlatformId : 'Trade Id',
        ChargingSessionId : 'Charging Session Id',
        ChargingTime    : 'Charging Time',
        ChargingMeter   : 'Charging Meter',
        AllChargingStation  : '@:All @:ChargingStation',
        AllCategories   : 'All Categories',
        CreditCard    : 'Credit Card',

        /* ~/trade/Charging.vue */
        OperatorType   : 'Operation category',
        ChargingStatus : 'Charging Status',
        TradeId        : 'Trade Id',

        /* ~/trade/Rfid.vue */
        CardNumber   : 'Card Number',

        /* ~/application/ApiKey.vue */
        ApiKeyHeader        : 'API key( Headers: X-API-Key={X_API_KEY} )',
        Description         : 'Description',
        CreateApiKey        : 'Create API key',
        RegisterApiService  : 'Register API service',
        DeleteApiKey        : 'Delete API key',

        /* ~/application/ApiKeySetting.vue */
        EditApiKey          : 'Edit API Key',
        ApiService          : 'API Service',
        EnterApiKeyDescription: '@:Input API key description',
        ApiServiceEndpoint: 'API URL',
        ApiServiceName: 'Service Name',
        ApiServiceDescription: 'Service Description',

        /* ~/pay/Tappay.vue */
        TappayAddAccount    : 'Add Tappay Account',
        TappayPartnerKey    : 'Partner Key',
        TappayMerchantId    : 'Merchant ID',
        InvoiceAccountId  : 'Company Invoice Name',
        InvoiceSharedSecret  : 'Company Tax ID Number',
        DuplicatedTappayAccount: 'You have created acquiring bank information (one operator is only allowed to set one set of acquiring bank information)',
        ConfirmDeleteTappay: "@:Confirm to @:Delete Tappay Account",

        /* ~/pay/TappaySetting.vue */
        TappayAccountSetting : 'Tappay Account Setting',
        EnterTappayPartnerKey : '@:Input @:TappayPartnerKey',
        EnterTappayMerchantId : '@:Input @:TappayMerchantId',
        EnterInvoiceAccountId : '@:Input @:InvoiceAccountId',
        EnterInvoiceSharedSecret : '@:Input @:InvoiceSharedSecret',
        IncorrectInput       : 'Input field format is incorrect',

        /* ~/user/User.vue */
        MemberSetting       : '@:Member @:Settings',
        MemberAccount       : '@:Member @:Account',
        MemberName          : '@:Member @:YourName',
        MemberSettingNote   : 'Note: If "Enabled Status" is set to [Disable], users will not be able to access any information on the website from the APP, just like a "blacklist"。',

        /* ~/resource/config/menu.json 、 ~/router/index.vue */
        DashBoard       : 'Charging Station Overview',
        ITArea          : 'IT Area' ,
        PaymentIssue    : 'Payment Issue',
        TappayAccounting: 'Tappay Accounting',
        Watch           : 'Watch',
        DB              : {
            station        : 'Charging Station Overview',
            live_info      : 'Instant Information',
            cp             : 'Equipment Status',
            graphic        : 'Graphic Control',
            map            : 'map mode',
            control        : 'Charging Connector Control',
            live_status    : 'Instant Status'
        },
        Report          : 'Report',
        Rp              : {
            station        : 'Charging Station @:Report',
            status         : 'Status @:Report',
            carbon_emmision: 'Carbon Emmision @:Report',
            usage_record : 'Usage-Rate @:Report',
            equipment_availability : 'Availability-Rate @:Report',
            popular_rate: 'Popularity-Rate @:Report'
        },
        Alert           : 'Alert',
        Ar                 : {
            setting        : '@:Alert Object Settings',
            record         : '@:Alert Event Record',
            event          : '@:Alert Event Setting',
            exceed         : 'Contract exceeded @:Alert'
        },
        Dispatching     :'Dispatching(ITRIS)',
        Dp              : {
            failure_report : 'Failure Report',
            pending        : 'pending',
            progress_query : 'Progress Query',
            setting        : 'Dispatching Settings',
            recording      : 'Customer Service Recording'
        },
        Maintenance     : 'Maintenance',
        Mt              : {
            analysis       : 'Statistical Analysis',
            report         : 'Maintenance @:Report',
            network        : 'Network Connection Status',
            firmware_update: 'Firmware Update'
        },
        ChargingStationM : 'Charging Station Management',
        CS              : {
            basic          : 'Basic Information',
            station        : 'Charging Station Settings',
            point          : 'Charging Point Settings',
            connector      : 'Charging Connector Settings',
            parkingspace   : 'Parking Space Settings',
            meter          : 'Meter Settings',
            rfid_setting   : 'RFID Settings',
            qrcode         : 'QRCode Generation'
        },
        Energy          : 'Energy',
        En              : {
            schedule       : 'Charging Schedule',
            dynamic        : 'Dynamic Current Sharing Charging',
            distribute     : 'Power Distribution Control',
            usagerecord    : 'Time of Use Settings',
            metercontract  : 'Meter Contract Settings',
        },
        Fee             : 'Fee Setting',
        Fe              : {

            chargingpoint : 'Charging Point Fee Settings',
        },
        Payment         : 'Payment Management',
        Pay             : {
            cloudinvoice  : 'Cloud Invoice',
            tappay        : 'Multiple Payments',
            user          : 'User Management',
            tradingrecord : 'Trading Record',
            chargingrecord: 'Charging Record',
            rfidrecord: 'RFID Card Record',
            todolist:   "ToDo List"
        },
        Application     :'Application Settings',
        Ap              : {
            api_management: 'API Management',
            push          : 'Push Notification',
        },
        System          : 'System Settings',
        Sys             : {
            profile       : 'Personal Profile',
            logs          : 'System Logs',
            rule          : 'Role Management',
            account       : 'Account Management',
            service       : 'Operator Management'
        },
        Activity        :'Announcement Information',
        Act             : {
            announcement  : 'Announcement',
            discount      : 'Discount',
            maintenance   : 'Maintenance Info'
        },

        OcpiManage      : 'OCPI',
        Ocpi            :{
            token       : 'Token',
            tokenconfig : 'Token Configures',
            cooperation : 'Cooperation Relation',
            api_management : 'API Management',
            command : 'Command',
            cdr: 'Session',
            logs: 'API Logs',
        }

    },
    /*
    cn: {
        ...elemnetcn,
        CPMS     : '充电桩服务管理系统',
        UseLang  : '使用语言',
        D       : {
            watch       : '监控',
            overview       : '充电站总览',
            instant        : '充电站即时信息',
            equipmentstatus: '充电站设备状态',
            graphi_control : '充电站图控管理',
            map            : '地图模式',
            remotecontrol  : '远程控制',
            live           : '即时状态'
        },
        Report          : {
            report      : '报表',
            charging       : '充电站充电报表',
            status         : '充电桩状态报表',
            carbon_emmision: '碳排放报表'
        }
    },
    */
    ja: {
        ...elemnetja,

        /* 比較常重複使用之共用部分 */
        CPMS            : '充電サービス管理システム',
        UseLang         : '言語',
        Account         : 'アカウント',
        Status          : '状態',
        Date            : '日付',
        Yes             : 'はい',
        No              : 'いいえ',
        Enable          : '有効にする',
        Disable         : '無効にする',
        Filter          : '過濾',
        FilterConditions: 'フィルター条件',
        Settings        : '設定',
        Edit            : '編集',
        Role            : '役割',
        Update          : 'アップデート',
        Add             : '追加',
        Create          : '作成する',
        Delete          : '消去',
        Confirm         : '確認する',
        Cancel          : 'キャンセル',
        Success         : '成功',
        Fail            : '失敗',
        All             : '全て',
        Save            : '保存',
        Input           : '入力',
        Output          : '出力',
        Charging        : '充電',
        ChargingStation : '充電ステーション',
        ChargingPoint   : '充電パイル',
        ChargingConnector:'充電コネクタ',
        ParkingSpace    : '駐車スペース',
        Power           : '電力',
        Error           : 'エラー',
        Query           : 'クエリ',
        Remote          : 'リモート',
        Trigger         : '引き金',
        Termination     : '終了',
        Start           : '始める',
        End             : '終わり',
        Time            : '時間',
        Email           : 'Eメール',
        Maximum         : '最大',
        Minimum         : '最小',
        Information     : '情報',
        Gender          : '性別',
        BindCard        : '支払い',
        Range           : '範囲',
        And             : 'そして',
        Detail          : '詳細',
        SampleTime      : 'サンプリング間隔',
        MultipleChoice  : '確認できる',
        Floor           : '階',

        /* ~/layout/component/Header.vue */
        Logout          : 'ログアウト',
        YouHaveLoggedOut: 'ログアウトしました',
        Hint            : 'ヒント',
        ConfirmLogout   : 'ログアウトしてもよろしいですか?',

        /* ~/utils/api.js、auth.js */
        LoginAgain      : '再度ログインします',
        NoSuchPermission: 'この権限がありません',

        /* ~/view/alert/record.vue  */
        ChargingStationS: '充電ステーションを選択する',

        /* ~/view/alert/setting.vue  */
        Operate         : '操作',

        /* ~/view/dashboard/Dashboard.vue  */
        Overview        : '概要',
        Amount          : '金額',
        Total           : '合計',
        TotalAmount     : '合計@:Amount',
        Unit            : '個',
        ConsumptionAmount: '月の消費量',
        Yuan            : '元',
        TotalPowerUsage : '総電力使用量',
        TotalPowerUsageM: '今月の総電力使用量',
        Degree          : '度',
        AlertInformation: 'アラームメッセージ',
        AlertInformationT:'今日のアラームメッセージ',
        Quantity        : '量',
        ChargingNow     : '充電中',
        Standby         : '待機する',
        Offline         : 'オフライン',
        ChargingNowNum  : '充電中の充電パイルの数',
        StandbyNum      : '待機中の充電ガンの総数',
        OfflineNum      : 'オフラインの合計数',
        ErrorsOccurred  : 'エラーが発生しました',
        ErrorsOccurredNum:'発生したエラーの数',

        /* ~/view/dashboard/control.vue  */
        ChargingPointS  : '@:ChargingPoint',
        ChargingConnectorS:'@:ChargingConnector',
        RemoteTriggerCP : 'リモートトリガー充電プロセス',
        RemoteTerminationCP:'充電プロセスのリモート終了',
        Currently       : '現在',
        Current         : 'current',
        Progress        : 'スケジュール',
        Accumulation    : '累積',
        NotYetObtained  : 'まだ取得されていない',

        /* ~/view/login/index.vue  */
        Login_Title     : '@:CPMS',
        Login           : 'ログイン',
        Logged_in       : 'ログインしました',
        Username        : 'ユーザー名',
        Password        : 'パスワード',
        Captcha         : 'キャプチャ',
        CaptchaGen      : 'クリックしてコードを再生成します',
        Required        : '必須',
        Username_req    : 'アカウントが必要です',
        Username_length : 'アカウントの長さは少なくとも{n}文字である必要があります',
        Password_req    : 'パスワードが必要',
        Password_length : 'パスワードは少なくとも{n}文字の長さである必要があります',
        Complete_info   : '情報を完全に入力してください',

        /* ~/view/report/Station.vue  */
        VoltageV        : '電圧(V)',
        CurrentA        : '電流(A)',
        PowerW          : '電力(W)',
        Meter           : '電力計',
        MeterDegree     : '電力計(Wh)',
        IntervalDegree  : '間隔度(Wh)',
        ChargingProgress : '@:Charging@:Progress(%)',
        ExportReport    : '輸出レポート',
        DownloadThisPageCsv: 'このページ(CSV)',
        DownloadAllDataCsv:  'ダウンロード(csv)',
        DownloadAllDataXls:  'ダウンロード(xls)',
        DownloadAllDataXlsx: 'ダウンロード(xlsx)',

        /* ~/view/report/Status.vue  */
        StartDegree     : '開始度(Wh)',
        EndDegree       : '終了度(Wh)',
        EndReasonId     : '終了の理由(id)',

        /* ~/view/report/Carbon.vue  */
        ReportProductionTime: 'レポート作成時間',
        CarbonFactor    : '係数',
        CarbonNumber    : '排出量',
        CarbonPercentage: '割合',

        /* ~/view/station/Point.vue */
        List            : 'リスト',
        Connect         : '接続する',
        OCPPVersion     : 'OCPPのバージョン',
        ChargingConnectorNum: '@:ChargingConnectorの総数 ',
        Install         : 'インストール',

        /* ~/view/station/PointSetting.vue */
        PowerSupply     : '電源',
        Longitude       : '経度',
        Latitude        : '緯度',
        RatedOutputPower: '定格出力電力',
        Number          : 'シリアルナンバー',
        PlugType        : 'プラグの種類',
        Rate            : '料金(元/度)',

        /* ~/view/station/Station.vue */
        Addstation      : '追加充電ステーション',
        Name            : '名前',
        RaSystemServiceName     : 'RAシステムサービス名',
        RaSystemServiceType     : 'RAシステムサービスの種類',
        AccountingContractNumber: '会計契約番号',
        Code            : 'コード',
        Operator        : 'オペレーター',
        Location        : '位置',
        EnableStatus    : '有効ステータス',
        ExternalServices: '外部サービス',

        /* ~/view/station/StationSetting.vue */
        CountyCity      : '郡と市',
        TaipeiCity      : '台北市',
        NewTaipeiCity   : '新北市',
        TaoyuanCity     : '桃園市',
        TaichungCity    : '台中市',
        TainanCity      : '台南市',
        KaohsiungCity   : '高雄市',
        HsinchuCounty   : '新竹県',
        MiaoliCounty    : '苗栗県',
        ChanghuaCounty  : '彰化県',
        NantouCounty    : '南投県',
        YunlinCounty    : '雲林県',
        ChiayiCounty    : '嘉義県',
        PingtungCounty  : '屏東県',
        YilanCounty     : '宜蘭県',
        HualienCounty   : '花蓮県',
        TaitungCounty   : '台東県',
        Address         : '住所',

        /* ~/view/station/Meter.vue */
        Controller      : 'コントローラ',
        Device          : '装置',
        Sensor          : 'センサー',
        Path            : 'パス',
        OcppScType      : '動態均流',
        Max             : '最大',
        Control         : 'コントロール',
        NotControl      : '制御する必要はありません',
        Tag             : '監視ポイント',
        TagAddress      : 'アドレスを登録する',
        DataLength      : 'データ長',
        DataType        : 'データ・タイプ',
        ModelType       : 'レジスタメモリの種類',
        StandardTagName : '標準監視点名',
        TagUnit         : 'ユニット',

        /* ~/view/station/ParkingSpace.vue */
        ParkingType     : 'タイプ',

        /* ~/view/activity/Announcement.vue */
        Announcement    : '公告',
        Publish         : '発布',
        Title           : 'タイトル',
        Discount        : '割引',

        /* ~/view/system/Account.vue */
        CreateAccount   : '新建@:Account',
        EnabledStatus   : '有効ステータス',
        Enabled         : '有効にする',
        NotEnabled      : '有効になっていません',
        RolePermissions : '役割の権限',
        ConfirmDeleteAccount: "アカウントの削除を確認しますか?",
        SuccessDeleted  : '削除に成功しました',
        FailDeleted     : '削除に失敗しました',

        /* ~/view/system/AccountRoleSetting.vue */
        RoleSettings    : '@:RolePermissions @Settings',
        SuccessEdited   : '編輯@:Success',
        FailEdited      : '編輯@:Fail',

        /* ~/view/system/AccountSetting.vue */
        AccountSettings : 'アカウント設定',
        EnterAdminAccount: '管理者アカウントを入力してください',
        EnterPassword   : 'パスワードを入力する',
        YourName        : 'あなたの名前',
        InputYourName   : 'あなたの名前を入力してください',
        TelePhone       : '電話',
        InputTelephoneNum: '@:TelePhone番号を入力してください',
        WhetherToEnable: '有効にするかどうか',
        IncorrectAccount: 'アカウントの形式が正しくありません。電子メールの形式が必要です',
        DifferentPassword:'パスワードとは異なります',
        IncorrectFormat : '間違った形式',
        AccountSettingsNote : '注: パスワードを変更する必要がない場合は、パスワードフィールドを入力しないでください。',

        /* ~/view/system/Role.vue */
        DirectoryPermission: 'ディレクトリのアクセス許可',
		DirectoryPermissionSetting: '目錄權限@:Settings',
        DirectorySettingToolTip: '選擇指定角色設定目錄權限',
        CreateRole         : '新增@:Role',
        RoleList           : '@:Role@:List',
        UpdateDate         : '@:Update@:Date',
        CreateDate         : '@:Create@:Date',
        AddRole            : '@:Add@:Role',
        EditRole           : '@:Edit@:Role',
        RoleName           : '@:Role@:Name',

        /* ~/view/system/Service.vue */
        SelectAnOperator: 'オペレーターを選択してください',
        AllOperator     : '@:All@:Operator',
        CreateOperator  : '新建@:Operator',
        OperatorName    : '@:Operator名',
        OperatorCode : '@:Operator@:Code',
        QueryNameOrCode : 'クエリオペレータ名またはコード',
        Company         : '会社',
        CompanyName     : '会社名',
        Contact         : '連絡担当者',
        ConfirmDeleteOperator: "オペレータの削除を確認します",

        /* ~/view/system/ServiceSetting.vue */
        OperatorSettings : '@:Operator設定',
        CompanyEngName     : '会社名（英語）',
        ContactMail: '会社のメールボックス',
        ContactPhone: '会社の電話番号',
        ContactAddress: '会社の住所',
        Ban: '統一会社番号',
        CompanyWebUrl: '会社のURL',
        CompanyLogoUrl: 'ロゴのURL',
        CompanyTsAndCsURL: '利用規約URL',
        EnterOperatorName: '通信事業者名を入力してください',
        EnterOperatorCode: 'キャリアコードを入力してください',
        EnterCompanyName: '会社名を入力してください',
        EnterCompanyEngName: '会社名を英語で入力してください',
        EnterContact: '連絡先担当者を入力してください',
        EnterContactPhone: '会社の電話番号を入力してください',
        EnterContactAddress: '会社の住所を入力してください',
        EnterContactMail: '会社のメールアドレスを入力してください',
        EnterBan: '社内統一番号を入力してください',
        EnterCompanyWebUrl: '会社の公式ウェブサイトのアドレスを入力してください',
        EnterCompanyLogoUrl: '会社ロゴのURLを入力してください',
        EnterCompanyTsAndCsURL: '会社の利用規約の URL を入力してください',
        ServiceSettingNote: '注: 演算子コードの形式は、大文字の英語 (A ～ Z) 数字 (0 ～ 9) と記号 (_-) であり、長さは少なくとも 3 です',
        IncorrectMail: 'メールの形式が正しくありません',
        IncorrectCode: 'コード形式が正しくありません',

        /* ~/view/trade/Record.vue */
        Member          : 'メンバー',
        TradeType       : '取引タイプ',
        TradeAmount     : '取引額',
        TradeTime       : '取引時間',
        TradeStatus     : '取引状況',
        TradePlatformId : '取引プラットフォームID',
        ChargingSessionId : '充電識別コード',
        ChargingTime    : '充電時間',
        ChargingMeter   : '充電度',
        AllChargingStation  : '@:All @:ChargingStation',
        AllCategories   : 'すべてのカテゴリ',
        CreditCard    : 'クレジットカード',

        /* ~/trade/Charging.vue */
        OperatorType   : '操作カテゴリー',
        ChargingStatus : '充電状態',
        TradeId        : '取引番号',

        /* ~/trade/Rfid.vue */
        CardNumber   : 'カード番号',

        /* ~/application/ApiKey.vue */
        ApiKeyHeader        : 'API key( Headers: X-API-Key={X_API_KEY} )',
        Description         : '説明する',
        CreateApiKey        : 'APIキーを追加',
        RegisterApiService  : 'APIサービスの登録',
        DeleteApiKey        : 'APIキーの削除',

        /* ~/application/ApiKeySetting.vue */
        EditApiKey          : 'APIキーの編集',
        ApiService          : 'APIサービス',
        EnterApiKeyDescription: 'API キーの説明を入力してください',
        ApiServiceEndpoint: 'API URL',
        ApiServiceName: 'API名',
        ApiServiceDescription: 'APIの説明',

        /* ~/pay/Tappay.vue */
        TappayAddAccount    : 'Tappay情報を追加',
        TappayPartnerKey    : 'アカウントキー(Partner Key)',
        TappayMerchantId    : '販売者識別コード(Merchant ID)',
        InvoiceAccountId  : '請求書発行会社名',
        InvoiceSharedSecret  : '請求書を発行するための会社編集者',
        DuplicatedTappayAccount: '取得銀行情報を作成しました（1人のオペレータが設定できる取得銀行情報は1セットのみです）',
        ConfirmDeleteTappay: "Tappay情報の削除の確認",

        /* ~/pay/TappaySetting.vue */
        TappayAccountSetting : 'Tappayアカウント設定',
        EnterTappayPartnerKey : '@:Input@:TappayPartnerKey',
        EnterTappayMerchantId : '@:Input@:TappayMerchantId',
        EnterInvoiceAccountId : '@:Input@:InvoiceAccountId',
        EnterInvoiceSharedSecret : '@:Input@:InvoiceSharedSecret',
        IncorrectInput       : '入力フィールドの形式が正しくありません',

        /* ~/user/User.vue */
        MemberSetting       : '@:Member@:Settings',
        MemberAccount       : '@:Member@:Account',
        MemberName          : '@:Member@:YourName',
        MemberSettingNote   : '注: 「有効ステータス」が [無効] に設定されている場合、ユーザーは「ブラックリスト」を設定した場合と同様に、APP から Web サイト情報にアクセスできなくなります。',

        /* ~/resource/config/menu.json 、 ~/router/index.vue 、  */
        DashBoard       : '充電ステーションの概要',
        ITArea          : 'IT分野' ,
        PaymentIssue    : '物議を醸す出来事',
        TappayAccounting: 'Tappay デポジット',
        Watch           : 'モニター',
        DB              : {
            station        : '充電ステーションの概要',
            live_info      : 'インスタント情報',
            cp             : '充電設備の状態',
            graphic        : 'グラフィックコントロール管理',
            map            : 'マップモード',
            control        : '充電コネクタ制御',
            live_status    : 'インスタントステータス'
        },
        Report          :'報告',
        Rp              : {
            station        : '充電站充電報告',
            status         : '充電パイル状態報告',
            carbon_emmision: '二酸化炭素排出量',
            usage_record : 'チャージパイル使用率@:Report',
            equipment_availability : '設備稼働率@:Report',
            popular_rate: 'よく使われる使い方@:Report'
        },
        Alert           :'アラート',
        Ar                 : {
            setting        : 'アラームオブジェクトの設定',
            record         : 'イベント記録',
            event          : 'イベントアラーム設定',
            exceed         : '契約超過アラート'
        },
        Dispatching     :'派遣(ITRIS)',
        Dp              : {
            failure_report : '障害レポート',
            pending        : 'まだ処理されていないケース',
            progress_query : 'クエリの進行状況',
            setting        : '派遣設定',
            recording      : '顧客サービス記録管理'
        },
        Maintenance     : 'メンテナンス',
        Mt              : {
            analysis       : '統計分析',
            report         : 'メンテナンスレポート',
            network        : '網ネットワーク接続チェック',
            firmware_update: 'ファームウェアのアップデート'
        },
        ChargingStationM : '充電ステーションの管理',
        CS              : {
            basic          : '充電ステーションの基本情報',
            station        : '充電ステーションの設定',
            point          : '充電パイルの設定',
            connector      : '充電コネクタの設定',
            parkingspace   : '駐車スペースの設定',
            meter          : '電力計設定',
            rfid_setting   : 'RFIDの設定と管理',
            qrcode         : 'QRCodeの生成と管理'
        },
        Energy          : 'エネルギー管理',
        En              : {
            schedule       : '充電スケジュール',
            dynamic        : 'ダイナミック電流共有充電',
            distribute     : 'エネルギー分配制御',
            usagerecord    : '時間別電気料金設定',
            metercontract  : 'メーター契約設定',
        },
        Fee             : '料金設定',
        Fe              : {
            chargingpoint : 'チャージパイルレート設定',
        },
        Payment         : '支払い管理',
        Pay             : {
            cloudinvoice  : 'クラウド電子請求書',
            tappay        : '複数の支払い方法',
            user          : '会員管理',
            tradingrecord : 'チャージと取引記録',
            chargingrecord: '充電記録',
            rfidrecord: 'RFID取引記録',
            todolist:   'やることリスト'
        },
        Application     :'設定を管理する',
        Ap              : {
            api_management: 'API管理',
            push          : 'メンバープッシュ通知',
        },
        System          : 'システム設定',
        Sys             : {
            profile       : '個人情報',
            logs          : 'システムログ',
            rule          : '権限管理',
            account       : 'システムアカウント管理',
            service       : 'オペレーター管理'
        },
        Activity        :'活動情報',
        Act             : {
            announcement  : '最新のお知らせ',
            discount      : 'プロモーション情報',
            maintenance   : 'メンテナンス情報'
        },
        OcpiManage      : 'OCPI',
        Ocpi            :{
            token       : 'Token'
        }
    },
    /*
    ko: {
        ...elemnetko,
        CPMS     : 'CPS 관리 시스템',
        UseLang  : '언어',
        D       : {
            watch       : '감시 장치',
            overview       : '충전소 개요',
            instant        : '실시간 정보',
            equipmentstatus: '충전소 설비현황',
            graphi_control : '사진 컨트롤 관리',
            map            : '지도 모드',
            remotecontrol  : '리모콘',
            live           : '인스턴트 상태'
        },
        Report          : {
            report      : '보고서',
            charging       : '충전소 충전 보고서',
            status         : '충전소 현황 보고',
            carbon_emmision: '탄소 배출 보고서'
        },
    }, */
}

const dateTimeFormats = {
    'tw': {
        date: {
          year   : 'numeric',
          month  : 'long',
          day    : '2-digit',
          weekday: 'long',
        },
        time: {
          hour   : '2-digit',
          minute : '2-digit',
          second : '2-digit',
          hour12 : false,
          timeZoneName : 'shortGeneric',
        }
      },
    'en': {
        date: {
            year   : 'numeric',
            month  : 'long',
            day    : '2-digit',
            weekday: 'long',
          },
          time: {
            hour   : '2-digit',
            minute : '2-digit',
            second : '2-digit',
            hour12 : false,
            timeZoneName : 'shortGeneric',
          }
    },
    'ja': {
        date: {
            year   : 'numeric',
            month  : 'long',
            day    : '2-digit',
            weekday: 'long',
          },
          time: {
            hour   : '2-digit',
            minute : '2-digit',
            second : '2-digit',
            hour12 : false,
            timeZoneName : 'shortGeneric',
          }
    }
  }

// 通過建立 VueI18n 實體,名為i18n
const i18n = new VueI18n({
    locale: 'tw',                 // 設置地區
    messages,                     // 設置地區訊息
    dateTimeFormats,              // 設置地區日期時間
    fallbackLocale: [ 'tw','en' ] //
  })

  export default i18n; //<--
