 <template>
  <div >
     <!--篩選區塊-->
     <el-row>
       <el-button
          style="float: left;"
          size="small"
          type="primary"
          icon="el-icon-tickets"
          v-if="(list!=undefined&&list!=null&&list.length>0)&&(list==undefined||list==null||list.length<=0)"
          @click="exportReport()"
        >{{$t('ExportReport')}}
       </el-button>
   
       <div style="float: left;">
       <!--listAll Export to XLSX-->
       <excel-xlsx
         :data="listXls" :columns="xlsxColumns" :file-name="filenameAllXlsx" :file-type="'xlsx'" :sheet-name="'sheetname'"
         v-if="listXls!=undefined&&listXls!=null&&listXls.length>0"
         >
         <el-button type="success" size="mini" icon="el-icon-download" style="display: inline-block" @click="logOperate('report','/account-report/refund','EXPORT','匯出充電樁請款紀錄統計報表 xlsx')">{{$t('DownloadAllDataXlsx')}}</el-button>
       </excel-xlsx>
       <!--listAll Export to XLSX-->
       </div>
 
       <div style="float: left;">
        <span style="font-weight: bold; font-size: 1.2em;">日期區間:</span>
        <el-date-picker
            v-model="dateStart"
            size="small"
            class="filter-item"            
            type="date" 
            value-format="yyyy-MM-dd"
            :placeholder="listQuery.startOn">
        </el-date-picker>             
        <el-date-picker
            v-model="dateEnd"
            size="small"
            class="filter-item"            
            type="date"
            value-format="yyyy-MM-dd"
            :picker-options="endDatePickerOptions"
            :placeholder="listQuery.endOn">
      </el-date-picker>    
      </div>
      <div style="float: right;">
        <span style="font-weight: bold; font-size: 1.2em;">交易編號:</span>
        <el-input size="small" v-model="listQuery.Id" placeholder="交易編號" class="filter-item" clearable />
      </div>
      <div style="float: right;">
        <span style="font-weight: bold; font-size: 1.2em;">發票號碼:</span>
        <el-input size="small" v-model="listQuery.invoiceNumber" placeholder="發票號碼" class="filter-item" clearable />
      </div>
     </el-row>

     <el-row style="text-align: left; font-size: .8em; padding-right: 10px; color: dimgrey; padding-left: 10px; margin-bottom: 5px;" >
            <span  v-if="listXls" >產製時間: {{ reportDate }} </span>
        </el-row>
     <!-- table -->
  
<div style= "margin: 50px 0;">
    <el-row> 
     <el-table
       size="mini"
       v-loading="listLoading" :data="list" style="width: 100%;" :cell-style="{padding: '0', height: '40px'}"
       @sort-change="tableSortChange"
     >     
       <!--<el-table-column align="center" prop="" min-width="30px"        label="No." /> -->
       <el-table-column align="center" prop="no" label="序號" />
       <el-table-column align="center" prop="payment_records_id"   label="訂單編號" />

      <el-table-column align="center" prop="customer_name" :show-overflow-tooltip="true"
                         :render-header="renderNameHeaderMethods"  label="發票抬頭">
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.customer_name != null" class="item" effect="dark" :content="scope.row.customer_name" placement="top-start">
            <span v-if="scope.row.customer_name != null">{{hideName(scope.row.customer_name)}}</span>
            </el-tooltip>
          </template>
        </el-table-column>
       <el-table-column align="center" prop="hd_number"   label="HD" />
       <el-table-column align="center" prop="invoice_number" label="發票號碼" />  
       <!--el-table-column align="center" prop="invoice_number" label="刷退卡號" /-->  
       <el-table-column align="center" prop="execute_refund_date" label="執行退款日" />
       <el-table-column align="center" prop="settle_refund_date"   label="銀行退款日" />       
       <el-table-column align="center" prop="settle_date" label="退費連結" v-if="permissions">        
        <template slot-scope="scope">                     
          <el-button  v-if="scope.row.progress==1 && scope.row.progress_status==0 && scope.row.refund_status==-1 " type="success" size="mini" icon="el-icon-setting"  @click="manualRefund(scope.row)" />
          <!--el-button  v-if="(scope.row.progress!=1 || scope.row.progress_status!=0 || scope.row.refund_status!=-1) " type="success" size="mini" icon="el-icon-setting" disabled  @click="manualRefund(scope.row)" /-->
          <!--el-button  type="success" size="mini" icon="el-icon-setting"  @click="manualRefund(scope.row)" /-->
        </template>

       </el-table-column>
       <el-table-column align="center" prop="amount" label="退費金額" />
       <el-table-column align="center" prop="payment_type_c" label="付款方式" />  
       <el-table-column align="center" prop="send_registered_mail" label="支票掛號寄出日" />
       <el-table-column align="center" prop="check_number"   label="支票號碼" />
       <el-table-column align="center" prop="refund_status_c" label="退款狀態" />
       <el-table-column align="center" label="操作" width="70px" v-if="permissions">
        <template slot-scope="scope">
          <el-button  v-if="scope.row.refund_status==2 && permissions" type="success" size="mini" icon="el-icon-setting" @click="manualSelect(scope.row)" />         
          <!-- button v-else-if="scope.row.connectorState === '待機中'" type="success" size="mini" icon="el-icon-setting" @click="cmdSelect(scope.row)" / -->
          <!--el-button type="primary" size="mini" @click="showDetail(scope.row)" v-if="(scope.row.chargingSessionRecordId!=undefined && scope.row.chargingSessionRecordId!= null && scope.row.chargingSessionRecordId!= '')"><svg-icon iconName="view-details" /></el-button-->
          <!--el-button-- type="primary" size="mini" v-else disabled><svg-icon iconName="view-details" /></el-button-->
        </template>
      </el-table-column>
     </el-table> 
     <!--分頁組件-->
     <pagination v-show="total>0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size" @pagination="getList" />
</el-row> 
  </div>
  <el-dialog width="700px"  :visible.sync="dialogManualInput" @close="dialogOnClose()" >

<el-row align="left" style="font-size:18px"  >人工輸入  </el-row>
<br>
 <el-row><el-col align="left" :span="8" >交易編號:</el-col><el-col align="left" :span="6" v-if="this.dialogData"> {{ this.dialogData.id }}</el-col></el-row>
 <br>
<el-row><el-col align="left" :span="8">掛號號碼(14位數字):</el-col><el-col align="left" :span="6" v-if="this.dialogData"> 
       <el-input size="small" class="filter-item" v-model="dialogData.registeredMailNumber" placeholder="請輸入14位數字"  @input="validateInputMailNumber" clearable /></el-col>
       <p v-if="errorMessage1" style="color:red;">{{ errorMessage1 }}</p>
      </el-row>

<el-row><el-col align="left" :span="8">支票號碼(2個英文字母+7位數字):</el-col><el-col align="left" :span="6" v-if="this.dialogData"> 
       <el-input size="small" class="filter-item" v-model="dialogData.checkNumber" placeholder="請輸入兩個英文字母加7位數字"  @input="validateInputCheckNumber" clearable /></el-col>
       <p v-if="errorMessage2" style="color:red;">{{ errorMessage2 }}</p>
      </el-row>

<el-row><el-col align="left" :span="8">支票掛號寄出日(YYYY-MM-dd):</el-col><el-col align="left" :span="6" v-if="this.dialogData"> 
       <!--<el-input size="small" class="filter-item" v-model="dialogData.sendRegisteredMail" clearable /> -->
       <el-date-picker
       v-model="dialogData.sendRegisteredMail"
       size="small"
       class="filter-item"            
       type="date"
       value-format="yyyy-MM-dd"
       placeholder="支票掛號寄出日"
       clearable>
       </el-date-picker>  
      </el-col></el-row>

 <!--
    <el-row style="float: left;">
        <span style="font-weight: bold; font-size: 1.2em;">支票掛號寄出日(YYYY-MM-dd):</span>
        <el-input size="small" class="filter-item" clearable />
      </el-row>
      <el-row style="float: left;">
        <span style="font-weight: bold; font-size: 1.2em;">支票號碼(2英文+7個數字):</span>
        <el-input size="small" class="filter-item" clearable />
      </el-row>
      <el-row style="float: left;">
        <span style="font-weight: bold; font-size: 1.2em;">掛號號碼:</span>
        <el-input size="small" class="filter-item" clearable />
      </el-row>
    -->
   <br>

 <br>
<div style="text-align: center">
 <el-button size="small" @click="dialogManualInput = false">
   取消
 </el-button>
 <el-button size="small" type="primary" @click="manualInput()">
   確定
 </el-button>
 </div>
</el-dialog>
   </div>
 </template>
 
 <script>
 import Pagination from '@/components/Pagination';
import { mixins } from '@/views/common/mixins.js';
 /* 每隔10min刷新出jwt token*/
 
 
 export default {
     name: 'Status',
     components: {
         Pagination
     },
     mixins: [mixins],
     data() {
         return {
          endDatePickerOptions: {
        disabledDate: () => false // 初始化時不禁用任何日期
      },
            permissions : false,
             dialogWidth: 0,
             total: 0,
             stationOption    : undefined,
             chargeOption     : undefined,
             connectorOption  : undefined,
             chargePointOption:undefined,
             list: [],
             statisticsData: '',
             listAll: [],
             listXls: [],
             listCsv: [],
             listLoading: false,
             listQuery: {
                 //serviceId: this.$store.state.currentAccount.id,
                 //serviceId: this.$store.state.currentAccount.serviceInfo.id,
                // stationId        : undefined,
                 //chargepointId    : undefined,
                 //chargeConnectorId: undefined,
                 startOn:undefined, // this.$moment().subtract(30, "days").format("YYYY-MM-DD"),//30天前
                 endOn:  undefined,// this.$moment().format("YYYY-MM-DD"),
                 //queryDays: 31,
                 page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
                 size: 10,
                 sort: "id,asc",
             },
             errorMessage1: undefined,
             errorMessage2: undefined,
             size           : undefined,
             paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
             dialogManualInput: undefined,
             //queryDays: undefined,
             dateStart        : undefined,
             dateEnd          : undefined,
             dialogSettingVisible: false,
             dialogData: { 
                 id                   : undefined,
                 sendRegisteredMail   : undefined,
                 checkNumber          : undefined,
                 registeredMailNumber : undefined,
              },
             targetUrl:undefined,
             reportDate: undefined,
             json_fields: {
               "No"   : "no",
               "StationName"     : "stationName",
               "PointName"   : "pointName",
               "Count" : "count",
               "UsageRate"     : "usageRate",
             },
             xlsxColumns : [
                 {
                    // label: this.$t('ChargingConnector'),
                    label: '序號',
                     field: "no",
                 },
                 {
                     //label: this.$t('Account'),
                     label: '訂單編號',
                     field: "payment_records_id",
                 },
                 {
                     //label: this.$t('Start')+this.$t('Time'),
                     label: '發票抬頭',
                     field: "customer_name",
                 },
                 {
                     //label: this.$t('StartDegree'),
                     label: 'HD',
                     field: "hd_number",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '發票號碼',
                     field: "invoice_number",
                 },
                 /*{
                     //label: this.$t('End')+this.$t('Time'),
                     label: '刷退卡號',
                     field: "invoice_number",
                 },*/
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '執行退款日',
                     field: "execute_refund_date",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '銀行扣款日',
                     field: "settle_refund_date",
                 },
                 /*
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '退費連結',
                     field: "settle_date",
                 },*/
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '退費金額',
                     field: "amount",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '付款方式',
                     field: "payment_type_c",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '支票掛號寄出日',
                     field: "send_registered_mail",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '支票號碼',
                     field: "check_number",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '退費狀態',
                     field: "refund_status_c",
                 },
                 ],
             /* 匯出檔名 */
             //filenameCsv:     this.$moment().format("YYYYMMDD-HHmmss").toString() + "_UsageReport" + ".xls",
             //filenameAll:     this.$moment().format("YYYYMMDD-HHmmss").toString() + "_UsageReportAll" + ".xls",
             filenameAllXlsx: this.$moment().format("YYYYMMDD-HHmmss").toString() + "_InvoiceAll",
      
       // chartDataExist : false, // 是否有圖表資料
         //   xAxisData      : [],    // x軸 日期時間
           // yAxisData      : [],    // y軸  
           // y2AxisData     : [],    // y軸2        
         };
     },
   created() {
     //定時刷新
     //refreshToken();
 
     this.dialogWidth = this.setDialogWidth()
     this.$store.state.currentAccount.roleInfo.roleData.forEach(item => 
     {   if( item.name == "FINANCEADMIN" ) this.permissions = true;
     }
    );
    // this.getStationByServiceId();
 
     
     //if(this.$route.params.stationId != undefined) 
    // {
     // this.chargePointOption = this.$route.params.stationId;   
    //}
     //this.debounceGetList(this.getListAll, 2000) // 監聽輸入框，一有變化就送請求
     //this.resetPage(this.getList);
   },
   mounted() {
     window.onresize = () => {
       return (() => {
         this.dialogWidth = this.setDialogWidth()
       })()
     }
   },
   beforeDestroy() {
     console.log("Call stopRefreshToken");
     //stopRefreshToken();
   },
   watch: {
     paginationSelectedPage: {
       handler: function(newValue) {
         this.listQuery.page = newValue - 1
       }
     },

     /* 偵測若語系切換,則進行本組件切出去在切回來,以達本組件語系包含子組件(含對話視窗)都刷新 */
     '$i18n.locale': function(val) {
       console.log('Role組件重載前')
       this.$router.push( { path:'/refresh',query:{ fromPath:this.$route.fullPath } })
       console.log('Role組件重載完成')
       },
     /* ----------------------------------------------------------------------------- */
     dateStart: function(val) {
      if (val) {
        this.listQuery.startOn = this.dateStart
      } else {
        this.listQuery.startOn = undefined
      }
      //this.listQuery.page = 0;
      this.listXls= [];
      //this.listCsv= [];
       //this.resetPage(this.getList)   
    },   
    dateEnd: function(val) {
      if (val) {
        this.listQuery.endOn = this.dateEnd
      } else {
        this.listQuery.endOn = undefined
      }
      //this.listQuery.page = 0;
      this.listXls= [];
      //this.listCsv= [];
       this.resetPage(this.getList)  
    },
    'listQuery.Id': function(val) {
      //this.listQuery.page = 0
      if(val == "")
    {
      this.listQuery.Id = undefined
    }
       
      this.resetPage(this.getList)
    },      
    'listQuery.invoiceNumber': function(val) {
      //this.listQuery.page = 0
      if(val == "")
    {
      this.listQuery.invoiceNumber = undefined
    }
       
      this.resetPage(this.getList)
    },  
      },
   computed: {
    
  },
   methods: {
    handleStartDateChange(date) {
      this.dateEnd = undefined
      this.listQuery.endOn = undefined
      if (date) {
        const startDate = new Date(date);
        const threeMonthsLater = new Date(startDate.getFullYear(), startDate.getMonth() + 3, startDate.getDate());
        
        this.endDatePickerOptions = {
          disabledDate(time) {
            return time.getTime() < startDate.getTime() || time.getTime() > threeMonthsLater.getTime();
          }
        };
      } else {
        // 如果起始日期被清除，重置結束日期選擇器的選項
        this.endDatePickerOptions = {
          disabledDate: () => true
        };
      }
    },
    validateInputMailNumber() {
      //console.log(this.dialogData.registeredMailNumber.length)
      const regex = /\d{14}/;
      if (!regex.test(this.dialogData.registeredMailNumber)) {
        this.errorMessage1 = '輸入必須是14位數字';
      } else if(this.dialogData.registeredMailNumber.length!=14 ){
        this.errorMessage1 = '輸入必須是14位數字';
      } else {
        this.errorMessage1 = '';
      }
    },
    validateInputCheckNumber() {
      //console.log()

      const regex = /^[a-zA-Z]{2}\d*7/;
      if (!regex.test(this.dialogData.checkNumber)) {
        this.errorMessage2 = '輸入必須是兩個英文字母加上7位數字';
      }  else if(this.dialogData.checkNumber.length!=9 ){
        this.errorMessage2 = '輸入必須是兩個英文字母加上7位數字';
      } else {
        this.errorMessage2 = '';
      }
    
    },
    exportToCSV() {
    //  this.logOperate('report','/report/usage','EXPORT','匯出充電樁使用率統計報表 csv');

const csvContent = this.convertToCSV(this.listCsv); //utf-8 

const link = document.createElement("a");


// "\ufeff"是为了解决CSV中文
let blob = new Blob(["\ufeff" +csvContent],{type: 'text/csv;charset=utf-8;'});
let url = URL.createObjectURL(blob);
link.href = url;
link.setAttribute('download', this.filenameAllXlsx);
link.click();
},
convertToCSV(data) {
const columnDelimiter = ",";
const lineDelimiter = "\n";
const keys = Object.keys(data[0]);

let result = "";
result += keys.join(columnDelimiter);
result += lineDelimiter;

data.forEach(item => {
  let row = "";
  keys.forEach(key => {
    row += item[key] + columnDelimiter;
  });
  result += row.slice(0, -1);
  result += lineDelimiter;
});

return result;
},

     getList() {
       let number = this.page
       let size   = this.size
       this.listQuery.page = 0
       this.listLoading = true
//http://localhost:6783/api/v1/report/RefundRecords?startOn=2024-07-01&endOn=2024-08-22&page=0&size=15&sort=customer_name,desc
      this.targetUrl ="/api/v1/report/RefundRecords"; //?startOn=2024-07-28
 
       //this.axios.get('/api/v1/report/status', {params: this.listQuery}).then(res=> {
        this.axios.get(this.targetUrl, {params: this.listQuery}).then(res => {
         //console.log("Response:/api/v1/report/status [res.data]",res.data)
         this.list = res.data.content
        // this.listXls = res.data.content
         this.total = res.data.totalElements
         //this.list = this.contentList
         res.data.content.forEach(refund => {
          if(refund.payment_type == 1 ){
            refund.payment_type_c = "信用卡(Tappay)"
          }else if(refund.payment_type == 2 ){
            refund.payment_type_c = "Line Pay"
          }else {
             refund.payment_type_c = "Unknown"
          }

          if(refund.refund_status == 0 ){
            refund.refund_status_c = "已退款"
          }else if(refund.refund_status == 1 ){
            refund.refund_status_c = "退款中"
          }else if(refund.refund_status == 2 ){
            refund.refund_status_c = "退款失敗"
          }
          else {
             refund.refund_status_c = ""
          }

          //refund.execute_refund_date = refund.execute_refund_date.split(" ")[0] //去除掉時間
          //refund.settle_refund_date  = refund.settle_refund_date.split(" ")[0]  //去除掉時間
         })

         number = res.data.number
         size   = res.data.size

         let output = this.list.map( ( item , index ) => {
             item.no = index + number*size +1             
             return item
         })  
         //console.log(this.list)       
       })

      // this.targetUrl ="/api/v1/report/RequestPaymentTotal"; //?startOn=2024-07-28

//this.axios.get(this.targetUrl, {params: this.listQuery}).then(res => {
 // this.statisticsData = res.data
   this.exportReport()
//  console.log(this.statisticsData)
//})  
this.listLoading = false
     },

       exportReport(){
        let number = this.page
        let size   = this.size
       this.size          = this.listQuery.size
       this.listQuery.page=0
       this.listQuery.size=100000
 
       this.targetUrl ="/api/v1/report/RefundRecords"; //?startOn=2024-07-28
 

  this.axios.get(this.targetUrl, {params: this.listQuery}).then(res => {
   this.listXls = res.data.content
   res.data.content.forEach(refund => {
          if(refund.payment_type == 1 ){
            refund.payment_type_c = "信用卡(Tappay)"
          }else if(refund.payment_type == 2 ){
            refund.payment_type_c = "Line Pay"
          }else {
             refund.payment_type_c = "Unknown"
          }

          if(refund.refund_status == 0 ){
            refund.refund_status_c = "已退款"
          }else if(refund.refund_status == 1 ){
            refund.refund_status_c = "退款中"
          }else if(refund.refund_status == 2 ){
            refund.refund_status_c = "退款失敗"
          }
          else {
             refund.refund_status_c = ""
          }
         })


   number = res.data.number
   size   = res.data.size

   let output = this.listXls.map( ( item , index ) => {
       item.no = index + number*size +1             
       return item
   })         
 })
       this.listQuery.size=this.size
       //this.filenameCsv =    this.$moment().format("YYYYMMDD-HHmmss").toString() + "_UsageReport" + ".xls"
       //this.filenameAll =    this.$moment().format("YYYYMMDD-HHmmss").toString() + "_UsageReportAll" + ".xls"
       this.filenameAllXlsx= this.$moment().format("YYYYMMDD-HHmmss").toString() + "_InvoiceAll"
       this.listQuery.size=15
     },
     manualSelect(row) {
      this.dialogManualInput=true
      this.dialogData.id = undefined
      this.dialogData.registeredMailNumber = undefined
      this.dialogData.checkNumber = undefined
      this.dialogData.sendRegisteredMail = undefined
      this.dialogData.id=row.id
     },
     async manualInput() {
      console.table('RefundSetting-Input [params]:',this.dialogData);
      
      this.targetUrl ="/api/v1/report/RefundRecordsSet";
      await this.axios.put( this.targetUrl, this.dialogData ).then( 
        res => {
         this.listAll = res.data       
        },
        error => {
          alert("可能輸入有誤!!!")
        }      
      )      
      console.table('RefundRecordsSet.listAll:',  this.listAll)
      if( this.listAll.id == this.dialogData.id && this.listAll.registeredMailNumber == this.dialogData.registeredmailnumber &&
          this.listAll.checkNumber == this.dialogData.checknumber ) {
        this.dialogOnClose()        
      } 
     },
     manualRefund(row) {
      
        const refundModel = {
         paymentId:  row.payment_records_id
      };
      this.axios.put('/api/v1/payment/refund', refundModel).then(()=> {
         this.$message({
           showClose: true,
           message: "OK",
           type: 'success',
           onClose:()=>{
            this.getList();
           }
         })
         this.dialogDataOnClose() //close the window
         window.location.reload() //refresh the result
      }).catch(err => {
         console.log('err:' + err)
         if(err.data.includes('NotAdmin')) {
           this.$message({
             message: this.$t('NotAdmin'),
             type: 'error',
             onClose:()=>{
              this.getList();
              
           }
           })
         } else if(err.data.includes('RefundFail')) {
           this.$message({
             message: this.$t('RefundFail'),
             type: 'error',
             onClose:()=>{
              this.getList();
              
           }
           })
         } else if(err.data.includes('OldRecord')) {
           this.$message({
             message: this.$t('OldRecord'),
             type: 'error',
             onClose:()=>{
              this.getList();
              
           }
           })
         } else {
           this.$message({
             message: "Fail",
             type: 'error',
             onClose:()=>{
              this.getList();
             
           }
           })
         }
         //this.dialogDataOnClose() 

      })      
     },
     dialogOnClose() {
      this.dialogManualInput=false
      console.table('RefundSetting-dialogClose [params]:',this.dialogData);
      this.getList();
     }
   }
 }
 
 </script>
 